import Logo from '../Logo'
import './index.css'
import SecondaryNavAction from '../SecondaryNavAction'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { Course, NavigationAction } from '../../types'
import { useNavigate } from 'react-router-dom'

interface SecondaryNavBarProps {
  title: string;
  subtitle?: string;
  backTo?: any;
  courses?: Course[];
  onClickActions?: NavigationAction[];
}

function SecondaryNavBar ({
  title,
  subtitle,
  backTo,
  courses,
  onClickActions
}: SecondaryNavBarProps) {
  const navigate = useNavigate()
  const getCourseLogos = () =>
    courses?.map((course, index) => (
      <div className="subnav-logo-container" key={index}>
        <Logo
          logoUrl={course?.logoUrl}
          alt={'course logo'}
          name={course?.name}
        />
        <h1 className="subnav-program-name">{course?.name}</h1>
      </div>
    ))

  return (
    <div className="subnav-header">
      <div className="subnav-title-container">
        {backTo && (
          <div
            className='back-button'
            onClick={() => navigate(backTo)}
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none'
            }}
          >
            <ArrowBackIosNewRoundedIcon fontSize="medium" />
            <div
              className={`title-container ${subtitle ? 'with-subtitle' : ''}`}
              style={{ marginLeft: '0.5rem' }}
            >
              <h1>{title}</h1>
              {subtitle && <div className="subtitle">{subtitle}</div>}
            </div>
          </div>
        )}
        {!backTo && (
          <div className={`title-container ${subtitle ? 'with-subtitle' : ''}`}>
            <h1>{title}</h1>
            {subtitle && <div className="subtitle">{subtitle}</div>}
          </div>
        )}
      </div>
      {(onClickActions?.length ?? 0) > 0 && (
        <div className="subnav-actions">
          {onClickActions?.map((item, index) => (
            <SecondaryNavAction
              key={index}
              actionClassName={item.actionClassName}
              action={item.action}
              actionTitle={item.actionTitle}
            />
          ))}
        </div>
      )}
      {courses && (
        <div className="subnav-program-container">{getCourseLogos()}</div>
      )}
    </div>
  )
}

export default SecondaryNavBar
