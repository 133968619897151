import { Link } from 'react-router-dom'
import clsx from 'clsx'

interface ButtonProps {
  children: React.ReactNode
  onClick?: () => void
  to?: string
  href?: string
  className?: string
  secondary?: boolean
  size?: 'sm' | 'md' | 'lg'
}

function Button ({ children, to, href, onClick, className, secondary, size, ...props }: ButtonProps) {
  const classNames = [className, 'button']
  if (secondary) classNames.push('secondary')
  if (size) classNames.push(size)

  const nextClassName = clsx(classNames)

  if (to) {
    return (
      <Link to={to} className={nextClassName} {...props}>
        {children}
      </Link>
    )
  }

  if (href) {
    return (
      <a href={href} className={nextClassName} {...props}>
        {children}
      </a>
    )
  }

  return (
    <button className={nextClassName} onClick={onClick} {...props}>
      {children}
    </button>
  )
}

export default Button
