import { Space } from '../../types'
import ManageSpaceSettings from '../../components/ManageSpaceSettings'

interface CreateOrEditSpaceProps {
  existingSpace?: Space | null
  openedFromAdmin?: boolean
}

function CreateOrEditSpace ({ existingSpace, openedFromAdmin }: CreateOrEditSpaceProps) {
  return (
    <ManageSpaceSettings existingSpace={existingSpace} openedFromAdmin={openedFromAdmin}/>
  )
}

export default CreateOrEditSpace
