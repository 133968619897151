import { isAuthorized } from '../../shared/permissions'
import { getOrganizationOwnerPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { Entity, roles } from '../../shared/enums'
import LoadingScreen from '../LoadingScreen'
import { useBillableSeatsQuery, useOrganizationSubscriptionQuery } from '../../shared/queryHooks'
import './index.css'
import { OrganizationRole } from '../../types'
import { Checkbox } from '@mui/material'
import { getCurrentOrganizationRole } from '../../shared/utilities'

interface SelectPermissionProps {
  person: OrganizationRole
  updatePerson: (person: Partial<OrganizationRole>) => void
  prevTitle?: string
}

function SelectPermission ({ person, updatePerson, prevTitle } : SelectPermissionProps) {
  const { currentUser } = useAuthContext()
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)

  const organizationRolesKey = {
    [roles.guest]: {
      value: roles.guest,
      displayTitle: 'Guest',
      details: 'Has access to basic learning and space functions but no account management privileges, and is limited to one space',
      isBillable: false
    },
    [roles.member]: {
      value: roles.member,
      displayTitle: 'Member',
      details: 'Has access to basic learning and space functions but no account management privileges',
      isBillable: true
    },
    [roles.admin]: {
      value: roles.admin,
      displayTitle: 'Admin',
      details: `Has full privileges to access and manage a ${Entity.Workspace}, but cannot take action on an Owner`,
      isBillable: true
    },
    [roles.owner]: {
      value: roles.owner,
      displayTitle: 'Owner',
      details: `Has full privileges to access and manage a ${Entity.Workspace}`,
      isBillable: true
    }
  }
  const { data: billingPlan } = useOrganizationSubscriptionQuery(currentUser?.currentOrganizationId ?? 0)
  const { isLoading, data: billableSeats } = useBillableSeatsQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser && !!billingPlan })

  if (isLoading) {
    return <LoadingScreen />
  }

  const currentUserIsOwner = isAuthorized(getOrganizationOwnerPolicy(currentUser?.currentOrganizationId), currentUser)

  function makeRoleSelection (e) {
    updatePerson({ [e.target.name]: e.target.value })
  }

  function isOwnerRoleAvailable (title) {
    return title === roles.owner ? currentUserIsOwner : true
  }

  const rolesDropDown =
    Object.values(organizationRolesKey).filter(role => isOwnerRoleAvailable(role.value))
      .filter(role => {
        if (!prevTitle) {
          return role.value !== roles.owner && role.value !== roles.admin
        }
        return true
      })
      .map(role =>
        <option
          key={role.value}
          data-testid={`permission-${role.value.toLowerCase()}-option`}
          value={role.value}>
          {role.displayTitle}
        </option>)

  function getDecreaseSeatsText (billableSeatsCount) {
    return `Will decrease your billable seats from ${billableSeatsCount} to ${parseInt(billableSeatsCount) - 1}`
  }
  function getIncreaseSeatsText (billableSeatsCount) {
    return `Will increase your billable seats from ${billableSeatsCount} to ${parseInt(billableSeatsCount) + 1}`
  }

  function getIncreaseBillingText (planType) {
    const isBilledMonthly = planType === 'month'
    return `Will increase your billing by ${isBilledMonthly ? '$4' : '$40'} per ${isBilledMonthly ? 'month' : 'year'}`
  }

  function getDecreaseBillingText (planType) {
    const isBilledMonthly = planType === 'month'
    return `Will decrease your billing by ${isBilledMonthly ? '$4' : '$40'} per ${isBilledMonthly ? 'month' : 'year'}`
  }

  function getNewRoleBillingText ({ nextRole, billableSeats, planType }) {
    const increaseText = (
      <>
        <li>{getIncreaseSeatsText(billableSeats)}</li>
        <li>{getIncreaseBillingText(planType)}</li>
      </>
    )
    return nextRole.isBillable
      ? increaseText
      : <li>Will not increase your billable seats</li>
  }

  function getEditRoleBillingText ({ prevRole, nextRole, billableSeats, planType }) {
    if (!prevRole.isBillable && nextRole.isBillable) {
      const increaseText = (
        <>
          <li>{getIncreaseSeatsText(billableSeats)}</li>
          <li>{getIncreaseBillingText(planType)}</li>
        </>
      )
      return increaseText
    } if (prevRole.isBillable && !nextRole.isBillable) {
      const decreaseText = (
        <>
          <li>{getDecreaseSeatsText(billableSeats)}</li>
          <li>{getDecreaseBillingText(planType)}</li>
        </>
      )
      return decreaseText
    }
  }

  function getBillingText ({ nextRole, prevRole, billableSeats, planType }) {
    if (!prevRole) {
      return getNewRoleBillingText({ nextRole, billableSeats, planType })
    }
    return getEditRoleBillingText({ nextRole, prevRole, billableSeats, planType })
  }

  return (
    <>
      <select
        disabled={currentOrganizationRole?.id === person?.id}
        data-testid="role-select"
        className="permission-option-container"
        value={person?.title}
        onChange={makeRoleSelection}
        name="title">
        {rolesDropDown}
      </select>
      {currentUser?.currentOrganizationPrimaryOwner && person?.title === roles.owner && currentOrganizationRole?.id !== person?.id && !!person?.user?.email &&
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <Checkbox
            data-testid="remove-person-checkbox"
            checked={!!person?.primaryOwner}
            onChange={() => updatePerson({ primaryOwner: !person?.primaryOwner })}
          />
        <div style={{ marginLeft: '1rem', opacity: '0.6' }}>Primary Owner will receive all billing notifications</div>
      </div>}
      <div className="permission-details">
        <div className="permission-details-title">Details</div>
        <ul className="permission-details-list">
          <li>{organizationRolesKey[person?.title].details}</li>
          {getBillingText({
            nextRole: organizationRolesKey[person?.title],
            prevRole: organizationRolesKey[prevTitle ?? ''] ?? null,
            planType: billingPlan?.interval,
            billableSeats
          })}
        </ul>
      </div>
    </>
  )
}

export default SelectPermission
