import { getSpaceLeaderPolicy, getOrganizationAdminPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import CheckIcon from '@mui/icons-material/CheckRounded'
import { useModal } from '../../context/ModalContext'
import AddOrEditPerson from '../../modals/AddOrEditPerson'
import { formatReadOnlyPhoneNumber, getCurrentOrganization, getCurrentOrganizationRole } from '../../shared/utilities'
import { rolesDisplayMap } from '../../shared/enums'
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import CakeRoundedIcon from '@mui/icons-material/CakeRounded'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import { Chip, Switch } from '@mui/material'
import Avatar from '../Avatar'
import { OrganizationRole } from '../../types'
import { parseDate } from '../../shared/dates'
import { PuffLoader } from 'react-spinners'
import { useEditPersonMutation } from '../../shared/queryHooks'
import { useQueryClient } from '@tanstack/react-query'
import './index.css'

interface OrganizationProfileProps {
  person: OrganizationRole,
  spaceId?: number
}

function OrganizationProfile ({ person, spaceId }: OrganizationProfileProps) {
  const { makeModal } = useModal()
  const { currentUser } = useAuthContext()
  const queryClient = useQueryClient()
  const editPerson = useEditPersonMutation()
  const currentOrganization = getCurrentOrganization(currentUser)
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const spaceLeaderRoles = currentOrganizationRole?.spaceRoles?.filter(role => role.title === 'LEADER')
  const isSpaceLeaderOfPerson = spaceLeaderRoles?.some(role => person?.spaceRoles?.some(personRole => personRole.spaceId === role.spaceId))
  const isSpaceLeaderOrAbove = isAuthorized(getSpaceLeaderPolicy(spaceId, currentUser?.currentOrganizationId), currentUser)
  const hasContactAccess = (spaceId !== null || spaceId !== undefined) ? isSpaceLeaderOfPerson : isSpaceLeaderOrAbove
  const isAdminOrOwner = isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)

  const activeChip = person?.status === 'ACTIVE' ? { icon: <CheckIcon />, label: 'Active' } : { label: 'Inactive' }

  function openEditPersonModal () {
    makeModal({
      modal: <AddOrEditPerson existingPerson={person} spaceId={spaceId}/>,
      title: 'Edit Person',
      disableFocus: true
    })
  }

  function formatBirthday (birthday: boolean) {
    if (birthday) {
      return `Birthday ${parseDate(person?.contact?.dob)?.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}`
    }
  }

  const email = person.contact?.email || person?.user?.email
  const phone = person?.contact?.phone

  const personFieldName = [
    {
      title: <MailOutlineRoundedIcon />,
      testid: 'person-email-field',
      metaData: email ? <a href={`mailto:${email}`}>{email}</a> : <span className="add-data" onClick={openEditPersonModal}>Add email</span>
    },
    {
      title: <LocalPhoneRoundedIcon />,
      testid: 'person-phone-field',
      metaData: phone
        ? <a href={`tel:${phone}`}>{formatReadOnlyPhoneNumber(phone)}</a>
        : <span className="add-data" onClick={openEditPersonModal}>Add phone</span>
    },
    {
      title: <CakeRoundedIcon />,
      testid: 'person-dob-field',
      metaData: formatBirthday(person?.contact?.dob !== null) || <span className="add-data" onClick={openEditPersonModal}>Add birthday</span>
    },
    {
      title: <PlaceRoundedIcon />,
      testid: 'person-address-field',
      metaData: person?.contact?.address || <span className="add-data" onClick={openEditPersonModal}>Add address</span>
    },
    {
      title: <RocketLaunchIcon />,
      testid: 'person-created-field',
      metaData: `Created ${parseDate(person?.contact?.createdAt)?.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}`
    }
  ]

  const personFields = personFieldName.map((field, index) => (
    <div className="organization-profile-field" key={index} data-testid={field.testid}>
      {field.title}
      <div className="organization-profile-field-metadata">{field.metaData}</div>
    </div>
  ))

  async function toggleShareContactData () {
    await editPerson.mutateAsync({
      ...person,
      contact: {
        ...person.contact,
        shareData: !person.contact?.shareData
      }
    })
    await queryClient.invalidateQueries()
  }

  return (
    <div className="organization-profile-wrapper" data-testid="profile-wrapper">
      <div className='organization-profile-header'>
        <Avatar className="organization-profile-avatar" firstName={person?.contact?.firstName} lastName={person?.contact?.lastName} avatar={person?.user?.avatar}/>
      </div>
      <div className="organization-profile-name-field" data-testid="organization-profile-name-field">
        {person?.contact?.firstName} {person?.contact?.lastName}
      </div>
      <Chip {...activeChip} size="small" variant="outlined" sx={{ marginRight: '0.5rem' }}/>
      <Chip label={rolesDisplayMap[person?.title]} size="small" variant="outlined" />
      {(isAdminOrOwner || (hasContactAccess && (currentOrganization?.shareContactData || person?.contact?.shareData))) && <>
        <hr />
        <div>
          {personFields}
        </div>
        {isAdminOrOwner && <>
          <div className="daily-checkin-settings-container" style={{ fontSize: '12px', marginTop: '2rem' }}> Share contact information with host
                {editPerson.isLoading ? <PuffLoader color="#fff" size={38} /> : <Switch checked={person?.contact?.shareData} onChange={() => toggleShareContactData()}/> }
              </div>
          <div className="organization-profile-footer">
            <div className="button" data-testid="edit-person-button" onClick={openEditPersonModal}>
              Edit Person
            </div>
          </div>
        </>}
      </>}
    </div>
  )
}

export default OrganizationProfile
