import packageJson from '../../package.json'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { config } from './config'

function setupSentry () {
  Sentry.init({
    dsn: config.SENTRY.DSN,
    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        tracingOrigins: ['localhost', 'api.connectbetter.io']
      })],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: 'frontend@' + packageJson.version
  })
}

export {
  setupSentry
}
