import { DisplayState, getCurrentOrganizationRole } from '../../shared/utilities'
import { useCreateActionMutation, useUpdateActionMutation } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { PuffLoader } from 'react-spinners'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import SpaceDropdown from '../../components/SpaceDropDown'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import { Action } from '../../types'
import { parseDate } from '../../shared/dates'

interface CreateOrEditActionProps {
  clearModal: () => void
  existingAction?: Action
  setActionMenu?: DisplayState<number | null>
}

function CreateOrEditAction ({ clearModal, existingAction, setActionMenu }: CreateOrEditActionProps) {
  const { currentUser } = useAuthContext()
  const createAction = useCreateActionMutation()
  const updateAction = useUpdateActionMutation()
  const organizationRole = getCurrentOrganizationRole(currentUser)
  const defaultNewAction: Action = {
    id: 0,
    description: '',
    organizationRoleId: organizationRole?.id ?? 0,
    organizationId: currentUser?.currentOrganizationId ?? 0,
    dueBy: new Date().toISOString(),
    spaceId: null,
    connectionId: null,
    actionType: null,
    status: null
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [action, setAction] = useState(existingAction || defaultNewAction)

  const buttonText = existingAction ? 'Save' : 'Create'
  const placeholderText = existingAction ? 'Edit action description...' : 'New action description...'
  const mutationLoading = createAction?.isLoading || updateAction?.isLoading

  function handleSubmit () {
    if (action?.description.trim() === '') {
      setErrorMessage('Please add a description')
      return
    }

    if (existingAction) {
      if (setActionMenu) {
        setActionMenu(null)
      }
      updateAction.mutate(action)
    } else {
      if (setActionMenu) {
        setActionMenu(null)
      }
      createAction.mutate(action)
    }
    clearModal()
  }

  return (
    <div>
      {errorMessage && <div className="action-error">{errorMessage}</div>}
      <div className="action-content-container">
        <label>Action description</label>
        <div className="action-input-container">
          <input
            onChange={(e) => setAction((prev) => ({ ...prev, description: e.target.value }))}
            value={action?.description}
            placeholder={placeholderText}
          />
        </div>
        <label>Due on</label>
        <div className="action-date-input-container">
          <DatePicker
            selected={parseDate(action?.dueBy)}
            placeholderText="Due by..."
            onChange={(date) => setAction((prev) => ({ ...prev, dueBy: date }))}
            showMonthDropdown
            dateFormat="MM/d/yyyy"
            minDate={new Date()}
          />
        </div>
        <div className="action-space-dropdown-container">
          <label>Space (optional)</label>
          <SpaceDropdown setAction={setAction} value={action?.spaceId} />
        </div>
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>
          Cancel
        </div>
        <div className="button" onClick={() => handleSubmit()}>
          {mutationLoading ? <PuffLoader color="#fff" size={21} /> : buttonText}
        </div>
      </div>
    </div>
  )
}

export default CreateOrEditAction
