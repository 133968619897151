import { useEffect, useState } from 'react'
import { getSpaceLeaderPolicy } from '../../../shared/policies'
import { isAuthorized } from '../../../shared/permissions'
import { getCurrentOrganizationRole } from '../../../shared/utilities'
import ConnectionUserDropdown from '../../../components/ConnectionUserDropdown'
import ListExercises from '../../../components/ListExercises'
import Addon from '../Addon'
import './index.css'
import { Connection, SpaceRole, User } from '../../../types'

interface CourseAddonProps {
  pinned: boolean
  setAsPinned: () => void
  connection: Connection
  currentUser?: User | null
  editCourse: boolean
}

function CourseAddon ({ pinned, setAsPinned, connection, currentUser, editCourse } : CourseAddonProps) {
  const [dropdownValueUserId, setDropdownValueUserId] = useState<number | null>(null)
  const currentUserOrgRole = getCurrentOrganizationRole(currentUser)
  const isSpaceLeaderOrAbove = isAuthorized(getSpaceLeaderPolicy(connection?.spaceId, currentUser?.currentOrganizationId), currentUser)

  useEffect(() => {
    if (connection?.space?.spaceRoles && connection?.space?.spaceRoles?.length > 0) {
      setDropdownValueUserId(connection?.space?.spaceRoles[0].organizationRoleId)
    }
  }, [])

  const handleChangeDropdown = (event) => {
    setDropdownValueUserId(parseInt(event.target.value))
  }

  const getPeople = (spaceRoles?: SpaceRole[] | null) => {
    const people = spaceRoles?.map(spaceRole => {
      return {
        contact: spaceRole?.organizationRole?.contact,
        organizationRoleId: spaceRole?.organizationRoleId
      }
    })
    return people
  }

  const filterForSpaceMembers = getPeople(connection?.space?.spaceRoles)

  const sortedPeople = filterForSpaceMembers?.sort((a, b) => (
    (`${a.contact?.firstName} ${a.contact?.lastName}`)
      .localeCompare(`${b.contact?.firstName} ${b.contact?.lastName}`)
  ))

  return (
    <Addon title={connection?.space?.courseVersion?.course?.name ?? ''} className="program" pinned={pinned} setAsPinned={setAsPinned}>
      {isSpaceLeaderOrAbove
        ? <div className="program-content">
            <ConnectionUserDropdown
              value={dropdownValueUserId}
              onChange={handleChangeDropdown}
              activePeople={sortedPeople}
            />
            {dropdownValueUserId && (
              <ListExercises
                organizationRoleId={dropdownValueUserId}
                spaceId={connection.spaceId}
                connectionId={connection.id}
                currentUser={currentUser}
                connectionUid={connection?.uid}
                readOnly={!editCourse && connection?.endTime != null}
                userActions = {connection?.spaceUserActions}
              />
            )}
          </div>
        : <div className="program-content">
            <ListExercises
              organizationRoleId={currentUserOrgRole?.id}
              spaceId={connection.spaceId}
              connectionId={connection.id}
              currentUser={currentUser}
              userId={currentUser?.id ?? 0}
              readOnly={connection?.endTime != null}
              userActions = {connection?.spaceUserActions}
            />
        </div>
      }
    </Addon>
  )
}

export default CourseAddon
