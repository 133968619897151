import { getSpaceLeaderPolicy, getOrganizationPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { DisplayState, getCurrentOrganizationRole } from '../../shared/utilities'
import { useCreateActionMutation, useDeleteActionMutation, useUpdateActionMutation } from '../../shared/queryHooks'
import { useState, useRef, forwardRef } from 'react'
import EmptyHero from '../../components/EmptyHero'
import goalsIllustration from '../../assets/goals.svg'
import DatePicker from 'react-datepicker'
import ConnectionUserDropdown from '../ConnectionUserDropdown'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import Avatar from '../Avatar'
import 'react-datepicker/dist/react-datepicker.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import './index.css'
import { Connection, SpaceRole } from '../../types'
import { ActionState } from '../../pages/Connection/ActionsAddon'
import { parseDate } from '../../shared/dates'

interface ConnectionActionsProps {
  connection: Connection
  pinned: boolean
  creating: ActionState
  setCreating: DisplayState<ActionState>
  editing: ActionState
  setEditing: React.Dispatch<ActionState>
  useOutsideAlerter: (refAssignment: any, refDueBy: any) => void
}

function ConnectionActions ({ connection, pinned, creating, setCreating, editing, setEditing, useOutsideAlerter }: ConnectionActionsProps) {
  const { currentUser } = useAuthContext()
  const createAction = useCreateActionMutation()
  const updateAction = useUpdateActionMutation()
  const deleteAction = useDeleteActionMutation()
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const isSpaceLeaderOrAbove = isAuthorized(getSpaceLeaderPolicy(connection?.spaceId, currentUser?.currentOrganizationId), currentUser)
  const isMember = isAuthorized(getOrganizationPolicy(currentUser?.currentOrganizationId), currentUser)

  const filterForSpaceMembers = getPeople(connection?.space?.spaceRoles ?? [])
  const sortedPeople = filterForSpaceMembers?.sort((a, b) => (
    (`${a.contact?.firstName} ${a.contact?.lastName}`)
      .localeCompare(`${b.contact?.firstName} ${b.contact?.lastName}`)
  ))

  const defaultNewAction = { description: '', connectionId: connection?.id, organizationRoleId: sortedPeople[0]?.organizationRoleId, organizationId: currentUser?.currentOrganizationId, uid: connection?.uid, dueBy: new Date(), spaceId: connection?.space?.id }

  const [newAction, setNewAction] = useState(defaultNewAction)
  const [existingAction, setExistingAction] = useState<any>('')
  const [editingAction, setEditingAction] = useState<any>('')

  const attendanceWrapperRef = useRef(null)
  const dueByWrapperRef = useRef(null)
  useOutsideAlerter(attendanceWrapperRef, dueByWrapperRef)

  function editAction (description, id, organizationRoleId, dueBy) {
    setEditingAction(id)
    setCreating({ dueByField: false, assignmentField: false })
    setExistingAction({ description, connectionId: connection?.id, organizationRoleId, uid: connection?.uid, dueBy: new Date(dueBy), id, spaceId: connection?.spaceId })
  }

  async function saveAction () {
    if (!existingAction?.description) return
    await updateAction.mutateAsync(existingAction)
    setEditingAction('')
  }

  async function removeAction (id) {
    await deleteAction.mutateAsync({ id, uid: connection?.uid ?? '', spaceId: connection?.spaceId, organizationRoleId: currentOrganizationRole?.id ?? 0 })
  }

  async function addAction () {
    if (!newAction?.description) return
    await createAction.mutateAsync(newAction)
    setNewAction(defaultNewAction)
  }

  function updateDueBy (date: Date, isCreating = false) {
    if (isCreating) {
      setNewAction(prev => ({ ...prev, dueBy: date }))
      setCreating({ dueByField: false, assignmentField: false })
    } else {
      setExistingAction(prev => ({ ...prev, dueBy: date }))
      setEditing({ dueByField: false, assignmentField: false })
    }
  }

  function updateAssignment (organizationRoleId: number, isCreating: boolean = false) {
    if (isCreating) {
      setNewAction(prev => ({ ...prev, organizationRoleId }))
      setCreating({ dueByField: false, assignmentField: false })
    } else {
      setExistingAction(prev => ({ ...prev, organizationRoleId }))
      setEditing({ dueByField: false, assignmentField: false })
    }
  }

  function getPeople (spaceRoles: SpaceRole[]) {
    if (isSpaceLeaderOrAbove) {
      const people = spaceRoles?.map(spaceRole => {
        return {
          contact: spaceRole?.organizationRole?.contact,
          organizationRoleId: spaceRole?.organizationRoleId
        }
      })
      return people
    } else {
      const people = spaceRoles?.filter(spaceRole => spaceRole?.organizationRoleId === currentOrganizationRole?.id).map(spaceRole => {
        return {
          contact: spaceRole?.organizationRole?.contact,
          organizationRoleId: spaceRole?.organizationRoleId
        }
      })
      return people
    }
  }

  const CustomInputPicker = forwardRef<any, any>(({ value, onClick }, ref) => {
    return (
      <div className="custom-date-picker-input" onClick={onClick} ref={ref}>
        <div className="custom-date-picker-input-value">
          {value}
        </div>
        <div className="down-arrow-icon">
          <KeyboardArrowDownIcon/>
        </div>
      </div>
    )
  })

  const sortByCurrentUserAndRole = (actionA, actionB) => {
    const actionASpaceRole = connection?.space?.spaceRoles?.find(role => role.organizationRoleId === actionA?.organizationRoleId)
    const actionBSpaceRole = connection?.space?.spaceRoles?.find(role => role.organizationRoleId === actionB?.organizationRoleId)

    const currentOrganizationRoleId = currentOrganizationRole?.id
    const actionARoleId = actionASpaceRole?.organizationRoleId
    const actionBRoleId = actionBSpaceRole?.organizationRoleId

    if (currentOrganizationRoleId === actionARoleId && currentOrganizationRoleId !== actionBRoleId) return -1
    if (currentOrganizationRoleId !== actionARoleId && currentOrganizationRoleId === actionBRoleId) return 1

    const actionARoleTitle = actionASpaceRole?.title
    const actionBRoleTitle = actionBSpaceRole?.title

    if (actionARoleTitle === 'LEADER' && actionBRoleTitle !== 'LEADER') return -1
    if (actionARoleTitle !== 'LEADER' && actionBRoleTitle === 'LEADER') return 1

    if (actionARoleTitle === 'MEMBER' && actionBRoleTitle !== 'MEMBER') return -1
    if (actionARoleTitle !== 'MEMBER' && actionBRoleTitle === 'MEMBER') return 1

    return 0
  }

  const actionDisplay = connection?.actions?.sort(sortByCurrentUserAndRole).map(action => {
    return (
      <div key={action?.id}>
        {editingAction === action?.id
          ? <div className="new-action-container">
                <input className={'update-action-input'} onChange={(e) => setExistingAction(prev => ({ ...prev, description: e.target.value }))} value={existingAction?.description} placeholder="Existing Action"/>
                <ScheduleRoundedIcon style={{ right: '10.6rem' }} onClick={() => setEditing({ dueByField: !editing?.dueByField, assignmentField: false })} />
                <div style={{ position: 'relative' }}>
                  {editing?.dueByField && <div className="action-edit-dueBy-dropdown" ref={dueByWrapperRef}>
                    <DatePicker
                      selected={existingAction?.dueBy}
                      placeholderText="Due by..."
                      onChange={(date) => updateDueBy(date) }
                      showMonthDropdown
                      dateFormat="MM/d/yyyy"
                      minDate={new Date()}
                      customInput={<CustomInputPicker />}
                    />
                  </div>
                  }
                </div>
                <AccountCircleRoundedIcon style={{ right: '8.8rem' }} onClick={() => setEditing({ assignmentField: !editing?.assignmentField, dueByField: false })}/>
                <div style={{ position: 'relative' }}>
                  {editing?.assignmentField && <div className="action-edit-assignement-dropdown" ref={attendanceWrapperRef}>
                    <ConnectionUserDropdown
                      value={newAction?.organizationRoleId}
                      onChange={(e) => updateAssignment(parseInt(e.target.value)) }
                      activePeople={sortedPeople}
                    />
                  </div>}
                </div>
                <div className="cancel-save-action-button-container">
                  <div className="button secondary" onClick={saveAction}>Save</div>
                  <div className="button secondary" onClick={() => setEditingAction('')}>Cancel</div>
                </div>
              </div>
          : <div>
              <div className="action-container">
                <div className={pinned ? 'description-display-pinned' : 'description-display'}>{action?.description}</div>
                <div style={{ display: 'flex' }}>
                  {(connection?.endTime && action?.status !== 'COMPLETED') && <div className="due-by">{parseDate(action?.dueBy)?.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</div>}
                  {(connection?.endTime && action?.status === 'COMPLETED') && <div className="due-by completed">Completed: {parseDate(action?.updatedAt)?.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</div>}
                  <Avatar className="avatar" firstName={action?.organizationRole?.contact?.firstName} lastName={action?.organizationRole?.contact?.lastName} />
                  {!connection?.endTime && <div className="edit-delete-action-button-container">
                    {(isSpaceLeaderOrAbove || (isMember && action?.organizationRoleId === currentOrganizationRole?.id)) && <div className="button secondary" onClick={() => editAction(action?.description, action?.id, action?.organizationRoleId, action?.dueBy)}>Edit</div>}
                    {(isSpaceLeaderOrAbove || (isMember && action?.organizationRoleId === currentOrganizationRole?.id)) && <div className="button secondary" onClick={() => removeAction(action?.id)}>Delete</div>}
                  </div>}
                </div>
              </div>
            </div>
        }
      </div>
    )
  })

  const newActionDisplay = (
    <>
      <div className="new-action-container">
        <input className={'action-input'} onChange={(e) => setNewAction(prev => ({ ...prev, description: e.target.value }))} value={newAction?.description} placeholder="New Action"/>
        <ScheduleRoundedIcon style={{ right: '5.6rem' }} onClick={() => setCreating({ dueByField: !creating?.dueByField, assignmentField: false })} />
        <div style={{ position: 'relative' }}>
          {creating?.dueByField && <div className="action-create-dueBy-dropdown" ref={dueByWrapperRef}>
            <DatePicker
              selected={newAction?.dueBy}
              placeholderText="Due by..."
              onChange={(date) => updateDueBy(date, true)}
              showMonthDropdown
              dateFormat="MM/d/yyyy"
              minDate={new Date()}
              customInput={<CustomInputPicker />}
            />
          </div>}
        </div>
        <AccountCircleRoundedIcon style={{ right: '3.8rem' }} onClick={() => setCreating({ assignmentField: !creating?.assignmentField, dueByField: false })}/>
        <div style={{ position: 'relative' }}>
          {creating?.assignmentField && <div className="action-create-assignement-dropdown" ref={attendanceWrapperRef}>
            <ConnectionUserDropdown
              value={newAction?.organizationRoleId}
              onChange={(e) => updateAssignment(parseInt(e.target.value), true) }
              activePeople={sortedPeople}
            />
          </div>}
        </div>
        <div className="new-action-button-container">
          <div className="button secondary" onClick={addAction}>Add</div>
        </div>
      </div>
    </>
  )

  return (
    <div>
      {(connection?.endTime && (actionDisplay?.length ?? 0) < 1) &&
        <EmptyHero
          image={goalsIllustration}
          title="Actions"
          description="You have no actions created with this connection. Start a new connect create or receive actions"
        />
      }
      <div>
        {actionDisplay}
      </div>
      {(isMember && !connection?.endTime) &&
        <div>
          {newActionDisplay}
        </div>
      }
    </div>
  )
}

export default ConnectionActions
