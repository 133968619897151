import { getOrganizationAdminPolicy } from '../../shared/policies'
import { useOrganizationUserCourseQuery } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { useState } from 'react'
import ExerciseProgressDisplay from '../ExerciseProgressDisplayCard'
import CoursePrestigeDisplay from '../CoursePrestigeDisplay'
import PersonSpaces from '../PersonSpaces'
import PersonNotes from '../PersonNotes'
import { logError } from '../../shared/logger'
import './index.css'
import { OrganizationRole } from '../../types'

interface PersonSubNavProps {
  person: OrganizationRole
  spaceId?: number
}

function PersonSubNav ({ person, spaceId }: PersonSubNavProps) {
  const { currentUser } = useAuthContext()
  const isActive = person?.status === 'ACTIVE'
  const isAdminOrAbove = isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)

  const [selectedNav, setSelectedNav] = useState<string>('Spaces')

  const {
    isLoading,
    isError,
    data: userCourses,
    error
  } = useOrganizationUserCourseQuery(person?.id, { enabled: !!currentUser })

  if (isLoading) {
    return null
  }

  if (isError) {
    logError(error)
  }

  const personSubNavEmptyState = isAdminOrAbove
    ? 'This person is not active. Please reactivate this person to access this information.'
    : 'This person is not active. Please contact your admin reactivate this person to access this information.'

  const subNavHeaderContent = [
    { title: 'Notes', testid: 'person-notes-subnav', action: () => setSelectedNav('Notes') },
    { title: 'Spaces', testid: 'person-spaces-subnav', action: () => setSelectedNav('Spaces') },
    { title: 'Courses', testid: 'person-pathway-subnav', action: () => setSelectedNav('Courses') }
  ]

  const subNavKey = {
    courses: 'Courses',
    spaces: 'Spaces',
    notes: 'Notes'
  }

  const subNavHeaders = subNavHeaderContent.map((header) => (
    <div
      key={header.title}
      className={
        selectedNav === header.title
          ? 'organization-profile-subnav-header-selected'
          : 'organization-profile-subnav-header-item'
      }
      onClick={header.action}
      data-testid={header.testid}
    >
      {header.title}
    </div>
  ))

  return (
    <div className="organization-profile-subnav-wrapper">
      <div className="organization-profile-subnav-headers">{subNavHeaders}</div>
      {isActive
        ? (
        <div>
          {selectedNav === subNavKey.courses && (
            <>
              <ExerciseProgressDisplay userCourses={userCourses} id={person?.id} spaceId={spaceId} person={person} />
              <CoursePrestigeDisplay id={person?.id} />
              </>
          )}
          {selectedNav === subNavKey.spaces && <PersonSpaces person={person} />}
          {selectedNav === subNavKey.notes && <PersonNotes person={person} />}
        </div>
          )
        : (
        <div>{personSubNavEmptyState}</div>
          )}
    </div>
  )
}

export default PersonSubNav
