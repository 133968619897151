import { useNavigate, useParams } from 'react-router-dom'
import PaidIcon from '@mui/icons-material/Paid'
import './index.css'
import Button from '../../components/Button'
import { PuffLoader } from 'react-spinners'
import ErrorPage from '../ErrorPage'
import { useCourseQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { useAuthContext } from '../../context/AuthContext'
import { roles } from '../../shared/enums'
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded'

function CourseDetail () {
  const { uid } = useParams()
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()

  const { data: course, isLoading, isError, error, refetch } = useCourseQuery(uid ?? '')

  if (isLoading) {
    return (
      <div className='program-container-wrapper'>
        <div className='program-container'>
          <PuffLoader color='#51565f' data-testid="program-loading" />
        </div>
      </div>
    )
  }

  if (isError) {
    logError(error)
    return (
      <ErrorPage message="Something didn't work quite right." action={refetch} actionTitle="Try again?" />
    )
  }

  if (course === null) {
    return (
      <ErrorPage message="404, this page does not exist" action={() => navigate('/')} actionTitle="Return home?" />
    )
  }

  function makeGetStartedLink (courseUid, currentUser) {
    const isOwnerOrAdmin = (currentUser?.organizationRoles?.some(orgRole => [roles.owner, roles.admin].includes(orgRole.title)))

    if (!currentUser) {
      return `/signup?course=${courseUid}`
    }

    if (isOwnerOrAdmin) {
      return `/organizations/connect?course=${courseUid}`
    }

    return `/organizations/new?course=${courseUid}`
  }

  return (
    <div className='program-container-wrapper'>
      <div className='program-media-container'>
        <video autoPlay muted={true} playsInline loop>
          <source src={`https://cdn.connectbetter.io/programs/${course?.uid}.mp4`} type="video/mp4" />
        </video>
      </div>
      <div className='program-container'>
        <div className='program-name' data-testid="program-name">{course?.name}</div>
        <div className='publisher-details-container'>
          <div className='publisher-logo' data-testid="publisher-logo">{course?.program?.publisherName[0]}</div>
          <div className='publisher-name' data-testid="publisher-name">{course?.program?.publisherName}</div>
          <div className='publisher-verified'><VerifiedRoundedIcon /></div>
        </div>
        <div className='program-description' data-testid="program-description">{course?.description}</div>
        <Button to={makeGetStartedLink(course?.uid, currentUser)} data-testid="get-started">Get Started</Button>
        {course?.requiresPaidResources && <div className='additional-paid-resources' data-testid="program-additional-paid-resources"><PaidIcon /> Additional paid resources required</div>}
      </div>
    </div>
  )
}

export default CourseDetail
