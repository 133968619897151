import { Achievement } from '../../types'
import './index.css'

interface AchievementItemProps {
  memberAchievements: Achievement[]
}

function AchievementItem ({ memberAchievements }: AchievementItemProps) {
  return (
    <div className="achievement-list">
      {memberAchievements.map(achievement =>
        <div key={achievement.id} className="achievement">
          <div>{achievement.name}</div>
          <div className="achievement-label">Achievement</div>
        </div>
      )}
    </div>
  )
}

export default AchievementItem
