import Addon from '../Addon'
import { useEffect, useState } from 'react'
import ConnectionActions from '../../../components/ConnectionActions'
import { Connection } from '../../../types'

interface ActionsAddonProps {
  pinned: boolean
  setAsPinned: () => void
  connection: Connection
}

export type ActionState = {
  assignmentField: boolean
  dueByField: boolean
}

function ActionsAddon ({ pinned, setAsPinned, connection }: ActionsAddonProps) {
  const actionState = { assignmentField: false, dueByField: false }
  const [creating, setCreating] = useState<ActionState>(actionState)
  const [editing, setEditing] = useState<ActionState>(actionState)

  function useOutsideAlerter (refAssignment: any, refDueBy: any) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (refAssignment.current && !refAssignment.current.contains(event.target)) {
          setCreating(prev => ({ ...prev, assignmentField: false }))
          setEditing(prev => ({ ...prev, assignmentField: false }))
        }
        if (refDueBy.current && !refDueBy.current.contains(event.target)) {
          setCreating(prev => ({ ...prev, dueByField: false }))
          setEditing(prev => ({ ...prev, dueByField: false }))
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [refAssignment, refDueBy])
  }

  return (
    <Addon title="Actions" className="tasks" pinned={pinned} setAsPinned={setAsPinned}>
      <ConnectionActions connection={connection} pinned={pinned} creating={creating} setCreating={setCreating} editing={editing} setEditing={setEditing} useOutsideAlerter={useOutsideAlerter}/>
    </Addon>
  )
}

export default ActionsAddon
