import { createContext, useContext, useCallback, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/CloseRounded'
import FocusTrap from 'focus-trap-react'

interface ModalProps {
  modal: JSX.Element
  clearModal?: () => void
  modalClose?: boolean
  enableBackdropClose?: boolean
  modalBackgroundClose?: boolean
  title?: string | JSX.Element
  withoutOverflow?: boolean
  modalSize?: 'LARGE' | 'NORMAL'
  enableEscapeClose?: boolean
  disableFocus?: boolean
  slideOut?: boolean
}

interface ModalContextProps {
  clearModal: () => void
  makeModal: (props: ModalProps) => void
  setTitle: (title: string | JSX.Element) => void
}

const ModalContext = createContext<ModalContextProps>({
  clearModal: () => {},
  makeModal: () => {},
  setTitle: () => {}
})

const Modal = ({
  modal,
  clearModal,
  modalClose,
  title,
  withoutOverflow,
  modalSize = 'NORMAL',
  enableBackdropClose,
  enableEscapeClose,
  disableFocus,
  slideOut
}: ModalProps) => {
  useEffect(() => {
    if (enableEscapeClose) {
      const bind = (e) => {
        if (
          !modalClose ||
        e.keyCode !== 27 ||
        (document.activeElement && ['INPUT', 'SELECT', 'TEXTAREA'].includes(document.activeElement.tagName))
        ) {
          return
        }
        if (clearModal) clearModal()
      }
      document.addEventListener('keyup', bind)
      return () => document.removeEventListener('keyup', bind)
    }
  }, [modal, clearModal])

  const modalDisplay = (
    <div className="modal-overlay">
      <div
        className="modal-close"
        style={{ backdropFilter: slideOut ? 'none' : undefined, backgroundColor: slideOut ? 'rgb(0 0 0 / 0%)' : undefined }}
        data-testid="modal-close-background"
        onClick={enableBackdropClose ? clearModal : undefined}
      />
      <div
        className={`modal ${slideOut ? 'modal-slide-out' : modalSize === 'LARGE' ? 'modal-large-size' : 'modal-normal-size'} ${
          withoutOverflow ? 'without-overflow' : ''
        }`}
      >
        <div className="modal-header">
          <div className="modal-title">{title}</div>
          {modalClose && (
            <CloseIcon
              tabIndex={0}
              className="modal-close-button"
              data-testid="modal-close-button"
              onClick={clearModal}
            />
          )}
        </div>
        <div className="modal-content">{modal}</div>
      </div>
    </div>
  )

  const modalFocusDisplay = (
    <FocusTrap focusTrapOptions={{ tabbableOptions: { displayCheck: 'none' }, initialFocus: false }}>
      {modalDisplay}
    </FocusTrap>
  )

  return (
    <>
      {disableFocus ? modalDisplay : modalFocusDisplay}
    </>
  )
}

const ModalProvider = (props) => {
  const [modal, setModal] = useState<ModalProps['modal'] | null>(null)
  const [modalClose, setModalClose] = useState<boolean>(true)
  const [modalBackgroundClose, setModalBackgroundClose] = useState<boolean>(true)
  const [title, setTitle] = useState<string | JSX.Element>('')
  const [withoutOverflow, setWithoutOverflow] = useState<boolean>(false)
  const [modalSize, setModalSize] = useState<'LARGE' | 'NORMAL'>('NORMAL')
  const [disableFocus, setDisableFocus] = useState<boolean | undefined>()
  const [slideOut, setSlideOut] = useState<boolean>(false)

  const clearModal = useCallback(() => {
    setTitle('')
    setModalClose(true)
    setModal(null)
    setWithoutOverflow(false)
    setDisableFocus(false)
  }, [setModal, setTitle, setModalClose, setWithoutOverflow])

  function makeModal ({ modal, modalClose, modalBackgroundClose, title, overflow, modalSize, disableFocus, slideOut }) {
    setModalClose(modalClose ?? true)
    setModalBackgroundClose(modalBackgroundClose ?? false)
    setTitle(title)
    setModal(modal)
    setWithoutOverflow(overflow)
    setModalSize(modalSize)
    setDisableFocus(disableFocus)
    setSlideOut(slideOut)
  }

  return (
    <ModalContext.Provider value={{ clearModal, makeModal, setTitle }} {...props}>
      {props.children}
      {modal && (
        <Modal
          modal={modal}
          enableBackdropClose={modalBackgroundClose}
          clearModal={clearModal}
          modalClose={modalClose}
          title={title}
          withoutOverflow={withoutOverflow}
          modalSize={modalSize}
          disableFocus={disableFocus}
          slideOut={slideOut}
        />
      )}
    </ModalContext.Provider>
  )
}

const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  return context
}

export { ModalProvider, useModal }
