import { API } from 'aws-amplify'
import { ActionExercises, Checkin, Connection, Exercise, Goal, OrganizationCourse, OrganizationRole, Plan, Course, PromoCode, Space, User } from '../types'

const apiGet = async<T = any> (path: string): Promise<T> => {
  return API.get('ENGINEER_KIT_API', path, {})
}

const apiPut = async<T = any> (path: string, body?: object): Promise<T> => {
  return API.put('ENGINEER_KIT_API', path, body ?? {})
}

const apiPost = async<T = any> (path: string, body?: object): Promise<T> => {
  return API.post('ENGINEER_KIT_API', path, body ?? {})
}

const apiDelete = async<T = any> (path: string, body?: object): Promise<T> => {
  return API.del('ENGINEER_KIT_API', path, body ?? {})
}

const getLastOrganizationId = (): number => {
  return parseInt(localStorage.getItem('lastOrganizationId') ?? '0')
}

async function getUser (): Promise<User> {
  const lastOrganizationId = getLastOrganizationId()
  const validOrganizationId = lastOrganizationId !== 0 ? lastOrganizationId : null

  const user = await apiGet<User>(`/user${validOrganizationId !== null ? `?organizationId=${validOrganizationId}` : ''}`)
  const activeOrganizationRoles = user?.organizationRoles?.filter((role) => role.status !== 'INACTIVE') ?? []

  if (activeOrganizationRoles.find((role) => role.organization?.id === lastOrganizationId)) {
    return {
      ...user,
      currentOrganizationId: lastOrganizationId
    }
  }

  return {
    ...user,
    currentOrganizationId: activeOrganizationRoles[0]?.organization?.id || null
  }
}

async function putUser (user: User) {
  const newUser = await apiPut('/user', { body: user })
  return {
    ...newUser,
    currentOrganizationId: getLastOrganizationId() || user?.organizationRoles?.[0]?.organization?.id
  }
}

async function getExerciseListBySpace ({ organizationRoleId, spaceId }) {
  return await apiGet<Exercise[]>(
    `/exercise_progress?organizationRoleId=${organizationRoleId}${spaceId ? `&spaceId=${spaceId}` : ''}`
  )
}

async function getExerciseListByUser ({ organizationRoleId, userId }) {
  return await apiGet<Exercise[]>(
    `/exercise_progress?organizationRoleId=${organizationRoleId}&userId=${userId}`
  )
}

async function getSpaces ({ filter, isArchived }) {
  const queryString = filter && isArchived != null ? `?filter=${filter}&isArchived=${isArchived}` : filter ? `?filter=${filter}` : isArchived != null ? `?isArchived=${isArchived}` : ''
  return await apiGet<Space[]>(`/spaces${queryString}`)
}

async function postSpaces (space) {
  return await apiPost('/spaces', { body: space })
}

async function putSpaces (space) {
  const removedConnectionSpace = { ...space, connections: null }
  const putSpaceResponse = await apiPut(`/spaces/${space.id}`, { body: removedConnectionSpace })
  const connections = (await apiGet<Connection[]>(`/connections${space.id ? `?spaceId=${space.id}` : ''}`)).sort((a, b) => b.id - a.id)
  return { ...putSpaceResponse, connections }
}

async function getSpace (id) {
  const [space, connections] = await Promise.all([apiGet<Space>(`/spaces/${id}`), apiGet<Connection[]>(`/connections${id ? `?spaceId=${id}` : ''}`)])
  return { ...space, connections }
}

async function searchPeopleByEmail ({ email, spaceId }) {
  const results = await apiGet<OrganizationRole[]>(`/organization_roles/?email=${email}&spaceId=${spaceId}`)
  return !!results
}

async function getPeople () {
  return await apiGet<OrganizationRole[]>('/organization_roles')
}

async function getPeopleGallery () {
  return await apiGet<OrganizationRole[]>('/people_gallery')
}

async function getPeopleBySpace (spaceId) {
  return await apiGet<OrganizationRole[]>(`/organization_roles${spaceId ? `?spaceId=${spaceId}` : ''}`)
}

async function updatePerson (person) {
  return await apiPut(`/organization_roles/${person?.id}`, { body: person })
}
async function addPerson (person) {
  return await apiPost('/organization_roles', { body: person })
}

async function resendInvite (inviteCode) {
  return await apiPost(`/organization_roles/resend_invite/${inviteCode}`)
}

async function getOrganizationRole ({ id, spaceId }) {
  return await apiGet<OrganizationRole>(`/organization_roles/${id}${spaceId ? `?spaceId=${spaceId}` : ''}`)
}

async function putPersonStatus ({ organizationRoleId, status }) {
  return await apiPut(`/organization_roles/${organizationRoleId}:id/status`, {
    body: { organizationRoleId, status }
  })
}

async function putPeopleStatus ({ organizationRoles, status }) {
  return await apiPut('/organization_roles/status', {
    body: { organizationRoles, status }
  })
}

async function startConnection ({ spaceId, location, startTime }) {
  return await apiPost('/connections', { body: { spaceId, location, startTime } })
}

async function getConnection (connectionUid) {
  return await apiGet<Connection>(`/connections/${connectionUid}`)
}

async function endConnection (connectionUid) {
  return await apiPut(`/connections/${connectionUid}/end`)
}

async function updateConnection ({ connectionUid, startTime, location }) {
  return await apiPut(`/connections/${connectionUid}`, {
    body: { startTime: new Date(startTime), location }
  })
}

async function addOrganizationCourse (body) {
  return await apiPost('/organization_courses', { body })
}

async function getOrganizationCourses () {
  return await apiGet<OrganizationCourse[]>('/organization_courses')
}

async function getOrganizationUserCourses (organizationRoleId) {
  return await apiGet<Course[]>(`/user_courses?organizationRoleId=${organizationRoleId}`)
}

async function editContactNotes ({ contactId, notes, organizationRoleId }) {
  await apiPut(`/contacts/${contactId}/notes?organizationRoleId=${organizationRoleId}`, { body: { notes } })
}

async function postExerciseProgress ({ spaceId, exerciseId, connectionId, userId, organizationRoleId }) {
  return await apiPost('/exercise_progress', {
    body: { exerciseId, connectionId, userId, spaceId, organizationRoleId }
  })
}

async function deleteExerciseProgress ({ exerciseProgressId: id, spaceId, userId, organizationRoleId }) {
  return await apiDelete(`/exercise_progress/${id}}`, { body: { spaceId, userId, organizationRoleId } })
}

async function getUsernameSearchResults ({ username, spaceId }) {
  return await apiGet<User[]>(`/users/search/?username=${username}${spaceId ? `&spaceId=${spaceId}` : ''}`)
}

async function getOrganizationReports (organizationId) {
  return await apiGet(`/organizations/${organizationId}/reports`)
}

async function getOrganizationDailyReports (organizationId) {
  return await apiGet(`/organizations/${organizationId}/reports_daily`)
}

async function getWeeklyReport ({ organizationId, startWeekLocal, endWeekLocal }) {
  return await apiGet(
    `/organizations/${organizationId}/weekly_report?weekstart=${startWeekLocal}&weekend=${endWeekLocal}`
  )
}

async function getDailyReport ({ startDay, nextDay }) {
  return await apiGet(`/checkin/progress?start=${startDay}&next=${nextDay}`)
}

async function getCourse (uid) {
  return await apiGet<Course>(`/courses/${uid}`)
}

async function getPublicCourses () {
  return await apiGet<Course[]>('/course')
}

async function getPlans () {
  return await apiGet<Plan>('/plans')
}

async function createOrganizationCourse ({ organizationId, courseUid }) {
  return await apiPost(`/organizations/${organizationId}/courses`, { body: { courseUid } })
}

async function createOrganization ({ name, courseUid, domain }) {
  return await apiPost('/organizations', { body: { name, courseUid, domain } })
}

type updateOrganizationRequest = {
  organizationId: number
  name: string
  domain?: string
  logoUrl?: string
  memberSpaceCreation: boolean
  dailyCheckin?: boolean
  dailyCheckinDaysOfWeek?: string
  editConnection?: boolean
  defaultSpace: boolean
  shareContactData: boolean
}

async function updateOrganization ({
  organizationId,
  name,
  domain,
  logoUrl,
  memberSpaceCreation,
  dailyCheckin,
  dailyCheckinDaysOfWeek,
  editConnection,
  defaultSpace,
  shareContactData
}: updateOrganizationRequest) {
  return await apiPut(`/organizations/${organizationId}`, {
    body: { name, logoUrl, memberSpaceCreation, dailyCheckin, dailyCheckinDaysOfWeek, editConnection, domain, defaultSpace, shareContactData }
  })
}

async function updateSpaceRoles (request) {
  return await apiPut(`/organization_roles/${request?.organizationRoleId}/space_roles`, {
    body: request
  })
}

async function generateAvatarUploadUrlForOrganization (organizationId: number) {
  return await apiPost<string>('/organization/avatar', { body: { organizationId } })
}

async function generateAvatarUploadUrlForUser () {
  return await apiPost<string>('/user/avatar')
}

function getBillableSeats () {
  return apiGet<OrganizationRole[]>('/billable_seats')
}

async function getOrganizationSubscription (organizationId) {
  return await apiGet(`/organizations/${organizationId}/subscription`)
}

async function putPromoCode (body) {
  return await apiPut(`/organizations/${body.organizationId}/promo_code`, { body })
}

async function checkIsValidPromoCode ({ promoCode, organizationId }) {
  return await apiGet<PromoCode[]>(`/organizations/${organizationId}/promo_code/?code=${promoCode}`)
}

async function unfreezeOrganizationAndUpdatePaymentMethod ({ organizationId, paymentMethodId, customerId }) {
  return await apiPut(`/organizations/${organizationId}/update_payment`, {
    body: { paymentMethodId, customerId }
  })
}

async function getOrganizationGoals (organizationId) {
  return await apiGet<Goal[]>(`/organizations/${organizationId}/goals`)
}

async function createOrganizationGoal (title) {
  return await apiPost('/goal', { body: { title } })
}

async function updateOrganizationGoal ({ goalId, title }) {
  return await apiPut(`/goal/${goalId}`, { body: { title } })
}

async function deleteOrganizationGoal ({ goalId }) {
  return await apiDelete(`/goal/${goalId}`)
}

async function getUserActions ({ organizationRoleId, spaceId }) {
  return await apiGet<ActionExercises>(`/actions/?organizationRoleId=${organizationRoleId}${spaceId ? `&spaceId=${spaceId}` : ''}`)
}

async function createAction (body) {
  return await apiPost('/actions', { body })
}

async function updateAction ({ id, status, description, organizationRoleId, dueBy, spaceId }) {
  return await apiPut(`/actions/${id}`, {
    body: { status, description, organizationRoleId, dueBy, spaceId }
  })
}

async function deleteAction ({ id, organizationRoleId, uid, spaceId } : { id: number, organizationRoleId: number, uid?: string, spaceId?: number }) {
  return await apiDelete(`/actions/${id}`, { body: { organizationRoleId, uid, spaceId } })
}

async function updateConnectionAttendance ({ connectionId, uid, organizationRoleId, spaceId }) {
  return await apiPost('/connection_attendance', {
    body: { connectionId, uid, organizationRoleId, spaceId }
  })
}

async function deleteConnectionAttendance ({ connectionId, uid, organizationRoleId, spaceId }) {
  return await apiDelete('/connection_attendance', {
    body: { connectionId, uid, organizationRoleId, spaceId }
  })
}

async function getOrganizationRoleDailyCheckins () {
  return apiGet<Checkin[]>('/checkin')
}

async function createDailyCheckin (checkinResponses) {
  return await apiPost('/checkin/progress', { body: { checkinResponses } })
}

async function createOrUpdateCheckin (checkinResponses) {
  return await apiPut('/checkin/progress', { body: { checkinResponses } })
}

async function putDailycheckins (checkins) {
  return await apiPut('/checkin', { body: checkins })
}

async function getOrganizationRoleWithDailyCheckin () {
  return await apiGet<OrganizationRole[]>('/checkin/access')
}

async function updateOrganizationRoleDailyCheckinAccess (organizationRoles) {
  return await apiPut('/checkin/access', { body: organizationRoles })
}

async function updateOrganizationCourse ({ id, access, enabled, requiresReview, actionsApproved }) {
  return await apiPut(`/organization_courses/${id}`, { body: { access, enabled, requiresReview, actionsApproved } })
}

async function updateOrganizationRoleOrganizationCourseAccess ({ organizationId, body }) {
  return await apiPut(`/organizations/${organizationId}/courses/organization_role`, { body })
}

async function getCoursePresitge (organizationRoleId : number) {
  return await apiGet(`/course_prestige?organizationRoleId=${organizationRoleId}`)
}

async function putCoursePresitge ({ organizationRoleId, courseVersionId }) {
  return await apiPut('/course_prestige', { body: { organizationRoleId, courseVersionId } })
}

async function getFreedomCheckConnection (id) {
  return await apiGet(`/freedom_check_connection/${id}`)
}

async function getExercisesActionsForApproval (courseVersionId) {
  return await apiGet(`/exercises/action_approval/${courseVersionId}`)
}

async function getOrganizationTags () {
  return await apiGet('/organziation_tags')
}

async function putContactTag ({ contactId, names }) {
  return await apiPut(`/contacts/${contactId}/tags`, { body: { names } })
}

export {
  addPerson,
  createOrganization,
  createOrganizationCourse,
  deleteExerciseProgress,
  editContactNotes,
  endConnection,
  generateAvatarUploadUrlForOrganization,
  generateAvatarUploadUrlForUser,
  getBillableSeats,
  getWeeklyReport,
  getPlans,
  getUsernameSearchResults,
  getExerciseListBySpace,
  getExerciseListByUser,
  getSpace,
  getSpaces,
  getPeople,
  getPeopleGallery,
  getPeopleBySpace,
  getOrganizationCourses,
  addOrganizationCourse,
  getOrganizationRole,
  getOrganizationSubscription,
  getOrganizationUserCourses,
  getCourse,
  getPublicCourses,
  getConnection,
  getUser,
  postExerciseProgress,
  postSpaces,
  putSpaces,
  putPersonStatus,
  putUser,
  resendInvite,
  startConnection,
  updateSpaceRoles,
  updatePerson,
  updateConnection,
  updateOrganization,
  searchPeopleByEmail,
  putPromoCode,
  checkIsValidPromoCode,
  getOrganizationReports,
  unfreezeOrganizationAndUpdatePaymentMethod,
  getOrganizationGoals,
  createOrganizationGoal,
  updateOrganizationGoal,
  deleteOrganizationGoal,
  getUserActions,
  createAction,
  updateAction,
  deleteAction,
  updateConnectionAttendance,
  deleteConnectionAttendance,
  getOrganizationRoleDailyCheckins,
  createDailyCheckin,
  getOrganizationDailyReports,
  getDailyReport,
  putDailycheckins,
  getOrganizationRoleWithDailyCheckin,
  updateOrganizationRoleDailyCheckinAccess,
  createOrUpdateCheckin,
  updateOrganizationCourse,
  updateOrganizationRoleOrganizationCourseAccess,
  getCoursePresitge,
  putCoursePresitge,
  putPeopleStatus,
  getFreedomCheckConnection,
  getExercisesActionsForApproval,
  getOrganizationTags,
  putContactTag
}
