import { sortOrgCourses } from '../../shared/utilities'
import './index.css'

function SpaceCourseDropdown ({ orgCourses, setAttributeHandling, value, previousCourse, spaceRoles }) {
  const removeNullValue = value === null ? '' : value
  const spaceroleOrganinzationRoleIds = spaceRoles?.map(role => role?.organizationRoleId)

  return (
    <select disabled={!!removeNullValue && previousCourse} value={removeNullValue} onChange={(e) => setAttributeHandling({ value: e.target.value, attribute: 'courseVersionId' })}>
      <option value=''>Select Course...</option>
      {sortOrgCourses(orgCourses)
        ?.filter(course => (
          course?.enabled === true && !course?.requiresReview &&
            (JSON.parse(course.organizationRoleAccess)
              ?.filter(role => spaceroleOrganinzationRoleIds.includes(role?.organizationRoleId) && role?.access === true)
              ?.length === spaceroleOrganinzationRoleIds.length)))
        ?.map((courses) => (
            <option key={courses?.courseVersion?.id} value={courses?.courseVersion.id}>{courses?.courseVersion?.course?.name} v{courses?.courseVersion?.version}.0.0</option>
        ))}
    </select>
  )
}

export default SpaceCourseDropdown
