import { getSpaceLeaderPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import SpaceMembersDisplay from '../../components/SpaceMembersDisplay'
import ManageSpaceRoles from '../../components/ManageSpaceRoles'
import ManageSpaceSettings from '../../components/ManageSpaceSettings'
import { useState } from 'react'
import { Space } from '../../types'
import './index.css'

interface PersonSubNavProps {
  space: Space
}

function ManageSpace ({ space }: PersonSubNavProps) {
  const { currentUser } = useAuthContext()
  const isHostAndAbove = isAuthorized(getSpaceLeaderPolicy(space.id, currentUser?.currentOrganizationId), currentUser)

  const [selectedNav, setSelectedNav] = useState<string>('Members')

  const subNavHeaderContent = [
    { title: 'Members', action: () => setSelectedNav('Members') },
    { title: 'Invite', action: () => setSelectedNav('Invite') },
    { title: 'Settings', action: () => setSelectedNav('Settings') }
  ]

  const subNavKey = {
    members: 'Members',
    invite: 'Invite',
    settings: 'Settings'
  }

  const subNavHeaders = subNavHeaderContent.map((header) => {
    if (!isHostAndAbove && (header.title === subNavKey.invite || header.title === subNavKey.settings)) {
      return null
    }

    return (
      <div
        key={header.title}
        className={
          selectedNav === header.title
            ? 'organization-profile-subnav-header-selected'
            : 'organization-profile-subnav-header-item'
        }
        onClick={header.action}
      >
        {header.title}
      </div>
    )
  })

  return (
    <div className="organization-profile-subnav-wrapper">
      <div className="space-subnav-headers">{subNavHeaders}</div>
        {selectedNav === subNavKey.members && <SpaceMembersDisplay space={space} />}
        {isHostAndAbove && selectedNav === subNavKey.invite && <ManageSpaceRoles existingSpace={space} showAllMembers />}
        {isHostAndAbove && selectedNav === subNavKey.settings && <ManageSpaceSettings existingSpace={space} openedFromAdmin/>}
    </div>
  )
}

export default ManageSpace
