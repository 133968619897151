import SecondaryNavBar from '../../../components/SecondaryNavBar'
import { getCurrentOrganization } from '../../../shared/utilities'
import { useAuthContext } from '../../../context/AuthContext'
import { useQueryClient } from '@tanstack/react-query'
import { PuffLoader } from 'react-spinners'
import { Switch } from '@mui/material'
import { useUpdateOrganizationMutation } from '../../../shared/queryHooks'

function OrganizationSettingsContacts () {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const queryClient = useQueryClient()
  const editOrganization = useUpdateOrganizationMutation()

  async function toggleShareContactData (shareContactData: boolean) {
    await editOrganization.mutateAsync({
      name: currentOrganization?.name ?? '',
      organizationId: currentUser?.currentOrganizationId ?? 0,
      memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
      logoUrl: currentOrganization?.logoUrl ?? '',
      dailyCheckin: currentOrganization?.dailyCheckin ?? false,
      dailyCheckinDaysOfWeek: currentOrganization?.dailyCheckinDaysOfWeek ?? '{}',
      editConnection: currentOrganization?.editConnection,
      defaultSpace: currentOrganization?.defaultSpace ?? false,
      shareContactData
    })
    await queryClient.invalidateQueries()
  }

  return (
    <>
      <SecondaryNavBar title="Contacts"/>
      <div className="profile-settings-wrapper org-nav-settings">
        <div className="profile-setting-container action-toggle-container">
          <div className="component-toggle-container">
            <div className="actions-toggle-label">Share all contact information with host</div>
            <div className="daily-checkin-settings-container">
              {editOrganization.isLoading ? <PuffLoader color="#fff" size={38} /> : <Switch checked={currentOrganization?.shareContactData} onChange={() => toggleShareContactData(!currentOrganization?.shareContactData)}/> }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrganizationSettingsContacts
