import { useEditor } from '../../../shared/hooks'
import EditorAddon from '../EditorAddon'

function AgreementAddon (props) {
  const { uid, readOnly } = props
  const editor = useEditor(`agreement${uid}`, readOnly)
  return <EditorAddon type="agreement" editor={editor} {...props}/>
}

export default AgreementAddon
