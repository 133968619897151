import Avatar from '../../components/Avatar'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'

function PublicNav () {
  const { currentUser } = useAuthContext()

  return (
    <div className='navigation'>
      <div className='connectbetter-logo'><div className='logo-mark' />ConnectBetter<span className="tag">beta</span></div>
      {currentUser ? <div className='login'><Avatar className="nav-avatar" firstName={currentUser.firstName} lastName={currentUser.lastName} /></div> : <Link to="/login" className='login'>Login</Link>}
    </div>
  )
}

export default PublicNav
