import { useEditPersonNotesMutation, useEditContactNotesMutation } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import { useEffect, useState } from 'react'
import { OrganizationRole } from '../../types'

interface EditContactNotesProps {
  person: OrganizationRole
  isEditingContact?: boolean
}

function EditContactNotes ({ person, isEditingContact = false }: EditContactNotesProps) {
  const { setTitle, clearModal } = useModal()
  const editPersonNotes = useEditPersonNotesMutation()
  const editContactNotes = useEditContactNotesMutation()

  const [notes, setNotes] = useState(person?.contact?.notes ?? '')

  useEffect(() => {
    setTitle(`${person?.contact?.firstName}'s Notes`)
  }, [setTitle])

  const saveUserNotes = () => {
    if (isEditingContact) {
      editContactNotes.mutate({ contactId: person?.contact?.id, notes, organizationRoleId: person?.id })
    } else {
      editPersonNotes.mutate({ contactId: person?.contact?.id, notes, organizationRoleId: person?.id })
    }
  }

  return (
    <div data-testid='organization-user-notes-modal' className="notes-modal">
      <div className="notes-container">
          <textarea
              tabIndex={0}
              name='notes'
              data-testid="notes-textarea"
              className="notes-field"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Notes..."
            />
        </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>Cancel</div>
        <div className="button" onClick={saveUserNotes}>Save</div>
      </div>
    </div>
  )
}

export default EditContactNotes
