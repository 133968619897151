import {
  useOrganizationCourseQuery,
  useAddSpaceMutation,
  useEditSpaceMutation,
  useQueryClient
} from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { useModal } from '../../context/ModalContext'
import { useState } from 'react'
import { logError } from '../../shared/logger'
import { PuffLoader } from 'react-spinners'
import LoadingScreen from '../../components/LoadingScreen'
import SpaceCourseDropdown from '../../components/SpaceCoursesDropDown'
import GoogleAddressSearch from '../../components/GoogleAddressSearch'
import './index.css'
import { Switch } from '@mui/material'
import { Space, User } from '../../types'
import { useNavigate } from 'react-router-dom'

interface CreateOrEditSpaceProps {
  existingSpace?: Space | null
  openedFromAdmin?: boolean
}

function ManageSpaceSettings ({ existingSpace, openedFromAdmin }: CreateOrEditSpaceProps) {
  const { currentUser } = useAuthContext()
  const { clearModal } = useModal()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const addSpace = useAddSpaceMutation()
  const editSpace = useEditSpaceMutation()
  const buttonText = existingSpace ? 'Save' : 'Create Space'
  const hasPreviousCourse = !!existingSpace?.courseVersionId

  const addons = {
    course: true,
    notes: true,
    agreement: false,
    actions: true,
    goals: true,
    attendance: true,
    reflect: false
  }

  const defaultNewSpace = {
    id: 0,
    organizationId: currentUser?.currentOrganizationId,
    name: '',
    location: '',
    courseVersionId: null,
    purpose: '',
    addonPreferences: JSON.stringify(addons),
    spaceRoles: [
      {
        organizationRole: { contactId: getContactandOrganizationRoleId(currentUser)[0]?.contactId },
        organizationRoleId: getContactandOrganizationRoleId(currentUser)[0]?.id,
        title: 'LEADER'
      }
    ]
  } as Space

  const [space, setSpace] = useState<Space>(existingSpace ?? defaultNewSpace)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { isLoading, isError, data: orgCourses, error } = useOrganizationCourseQuery({ enabled: !!currentUser })
  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
  }

  function getContactandOrganizationRoleId (currentUser: User | null) {
    return (
      currentUser?.organizationRoles?.filter((role) => role?.organization?.id === currentUser?.currentOrganizationId) ??
      []
    )
  }

  const handleSubmit = async () => {
    if (space?.name.trim() === '') {
      setErrorMessage('Please enter a space name')
      return
    }
    if (existingSpace) {
      await editSpace.mutateAsync(space)
      await queryClient.invalidateQueries(['connections'])
      await queryClient.invalidateQueries([`organization:${currentUser?.currentOrganizationId}`, 'spaces'])
      clearModal()
      return
    }
    const response = await addSpace.mutateAsync(space)
    await queryClient.invalidateQueries([`organization:${currentUser?.currentOrganizationId}`, 'spaces'])
    const redirectPath = openedFromAdmin ? 'organizations/settings/spaces' : `spaces/${response.id}?created=true`
    navigate(redirectPath)
  }

  const setAttributeHandling = ({ value, attribute }) => {
    setSpace((previous) => ({ ...previous, [attribute]: value }))
    if (attribute === 'name') {
      setErrorMessage('')
    }
    if (attribute === 'purpose') {
      if (value.length >= 144) {
        setErrorMessage("The spaces's purpose cannot be more than 144 characters")
      } else {
        setErrorMessage('')
      }
    }
  }

  const parsedAddonPreferences = () => {
    return JSON.parse(space.addonPreferences ?? '{}')
  }

  const toggleAddonPreference = (addonPreference) => {
    const newAddonPreferences = {
      ...parsedAddonPreferences(),
      [addonPreference]: !parsedAddonPreferences()[addonPreference]
    }
    setSpace((previous) => ({ ...previous, addonPreferences: JSON.stringify(newAddonPreferences) }))
  }

  const addonsWidgetToggle = Object.keys(parsedAddonPreferences()).map((addon) => {
    if (addon === 'course' && !space?.courseVersionId) return null
    if (addon === 'reflect') return null

    return (
      <div key={addon} className="component-toggle-container">
        <div>{addon[0].toUpperCase() + addon.slice(1)}</div>
        <Switch checked={parsedAddonPreferences()[addon]} onChange={() => toggleAddonPreference(addon)} />
      </div>
    )
  })

  return (
    <div>
      {errorMessage && <div className="addEdit-space-error">{errorMessage}</div>}
      <div className="description">Spaces are where you connect with others.</div>
      <div className="program-dropdown-container">
        <label>Name</label>
        <input
          type="text"
          placeholder="Space name"
          value={space.name}
          maxLength={50}
          onChange={(e) => setAttributeHandling({ value: e.target.value, attribute: 'name' })}
        />
      </div>
      <div className="program-dropdown-container">
        <label>Purpose</label>
        <input
          type="text"
          placeholder="Overarching goal"
          maxLength={144}
          value={space.purpose ?? ''}
          onChange={(e) => setAttributeHandling({ value: e.target.value, attribute: 'purpose' })}
        />
      </div>
      <div className="program-dropdown-container">
        <label>Location or video call</label>
        <GoogleAddressSearch
          address={space.location}
          placeholder="Space location or video call link"
          onChange={(e) => setSpace((previous) => ({ ...previous, location: e.target.value }))}
          setAddress={(address) => setSpace((previous) => ({ ...previous, location: address }))}
        />
      </div>
      {(orgCourses?.length ?? 0) > 0
        ? (
        <div className="program-dropdown-container">
          <label>Attach course</label>
          <div className="program-dropdown-description">You can&apos;t change a course once added.</div>
          <div>
            <SpaceCourseDropdown
              orgCourses={orgCourses}
              setAttributeHandling={setAttributeHandling}
              previousCourse={hasPreviousCourse}
              value={space?.courseVersionId}
              spaceRoles={space?.spaceRoles}
            />
          </div>
        </div>
          )
        : (
        <></>
          )}
      {existingSpace && (
        <div className="program-dropdown-container">
          <label>Addons</label>
          <div className="addons-toggle-container">{addonsWidgetToggle}</div>
        </div>
      )}
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>
          Cancel
        </div>
        <div className="button start-connection-button" onClick={handleSubmit}>
          {addSpace.isLoading ? <PuffLoader color="#fff" size={21} /> : buttonText}
        </div>
      </div>
    </div>
  )
}

export default ManageSpaceSettings
