import './index.css'
import { Space } from '../../types'
import OrganizationSpaceSettingsMenu from '../OrganizationSpaceSettingsMenu'
import { useNavigate } from 'react-router-dom'
import Table from '../Table'

interface SpaceTableProps {
  data: Space[];
}

const SpaceNameCell = ({ row }) => {
  const navigate = useNavigate()

  return (
    <div
      className="contact-details-cell"
      onClick={() =>
        navigate(`/organizations/settings/spaces/${row.original?.id}`)
      }
      style={{ margin: '-0.25rem 0', marginLeft: '-0.5rem' }}
    >
      <div
        style={{
          fontSize: '1.15rem',
          fontWeight: 'bold',
          flex: 1,
          padding: '0.35rem'
        }}
      >
        {row.original.name}
      </div>
    </div>
  )
}

export const SpacesTable = ({ data }: SpaceTableProps) => {
  const title = 'Spaces'

  const fuzzyFilter = (row, columnId, value) => {
    if (columnId !== 'name') return false
    return (
      row
        .getValue(columnId)
        .toString()
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }

  const columns = [
    {
      header: 'Space',
      id: 'name',
      accessorFn: (row) => row.name,
      cell: ({ row }) => <SpaceNameCell row={row} />
    },
    {
      header: 'Status',
      accessorFn: (row) => row.isArchived ? 'Archived' : 'Active',
      cell: ({ row }) => row.original.isArchived ? 'Archived' : 'Active'
    },
    {
      header: 'Hosts',
      accessorFn: (row) =>
        row.spaceRoles?.filter((role) => role?.title === 'LEADER')?.length,
      cell: ({ row }) => (
        <>
          {
            row.original.spaceRoles?.filter((role) => role?.title === 'LEADER')
              ?.length
          }
        </>
      )
    },
    {
      header: 'Participants',
      accessorFn: (row) =>
        row.spaceRoles?.filter((role) => role?.title !== 'LEADER')
          ?.length,
      cell: ({ row }) => (
        <>
          {row.original.spaceRoles?.filter((role) => role?.title !== 'LEADER')
            ?.length}
        </>
      )
    },
    {
      header: '',
      id: 'spaceId',
      cell: ({ row }) => (
        <OrganizationSpaceSettingsMenu
          space={row.original}
          spaceId={row.original?.id}
        />
      ),
      enableSorting: false
    }
  ]

  return (
    <>
      <Table
        typeReferenceText={title}
        fuzzyFilter={fuzzyFilter}
        columns={columns}
        data={data}
        tableWrapperClass='spaces-table-container'
      />
    </>
  )
}
