import { useEditor } from '../../../shared/hooks'
import EditorAddon from '../EditorAddon'

function ReflectAddon (props) {
  const { uid, readOnly } = props
  const editor = useEditor(`reflections${uid}`, readOnly)
  return <EditorAddon type="reflect" editor={editor} {...props}/>
}

export default ReflectAddon
