import './index.css'
import { useNavigate } from 'react-router-dom'
import { formatConnectionDate } from '../../shared/utilities'
import { isAuthorized } from '../../shared/permissions'
import { getSpacePolicy } from '../../shared/policies'
import { Connection, User } from '../../types'

interface ConnectionsInProgressProps {
  spaceId: number
  organizationId: number
  connections: Connection[]
  currentUser?: User | null
}

function ConnectionsInProgress ({ spaceId, organizationId, connections, currentUser } : ConnectionsInProgressProps) {
  const navigate = useNavigate()

  if (connections.length === 0) {
    return null
  }

  return (
    <div className="connection-in-progress-history-list">
      <h3>Happening now</h3>
      {connections.map(connection => (
        <div className="connection-item" key={connection.uid}>
          <div className="connection-title" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="connection-live">Live</div>
            {formatConnectionDate(connection.startTime)}
          </div>
          <div style={{ display: 'flex' }}>
            {isAuthorized(getSpacePolicy(spaceId, organizationId), currentUser) &&
              <div className="button" onClick={() => navigate(`/connections/${connection.uid}`)} style={{ marginLeft: '0.5rem' }}>
                Join
              </div>
            }
          </div>
        </div>
      ))}
    </div>
  )
}

export default ConnectionsInProgress
