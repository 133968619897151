import { Link } from 'react-router-dom'
import Button from '../Button'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { getCurrentOrganization } from '../../shared/utilities'
import { useLocalStorage } from '../../shared/hooks'
import './index.css'
import { Entity } from '../../shared/enums'

export const Changelog = () => {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const showChangelog = isAuthorized(getOrganizationAdminPolicy(currentOrganization?.id), currentUser)
  const [dismissed, setDismissed] = useLocalStorage('dismissed-changelog')

  if (!showChangelog || dismissed) return null

  return (
    <div className="feature-update">
      <div className="feature-update-content">People tab has a new home. You can now find it <Link to="/people">under {Entity.Workspace} settings</Link>.</div>
      <Button className="secondary" onClick={() => setDismissed('true')}>Dismiss</Button>
    </div>
  )
}
