import { sortSpaceRolesByFirstName } from '../../shared/utilities'
import SpaceUserItem from '../SpaceUserItem'
import './index.css'

function SpaceMembersDisplay ({ space }) {
  return (
    <div className="space-members-container">
      <div className="space-members-list">
        {space?.spaceRoles?.sort(sortSpaceRolesByFirstName).map(spaceRole => (
          <SpaceUserItem
            key={spaceRole?.organizationRoleId}
            person={spaceRole?.organizationRole}
          />
        ))}
      </div>
    </div>
  )
}

export default SpaceMembersDisplay
