import AddEditAvatar from '../AddEditAvatar'
import { Switch } from '@mui/material'
import { FormInputType } from '../../types'

function ProfileSettings ({ user, onChange, setAvatar, editor, hasChangedAvatar, setHasChangedAvatar, setSaveMessage, avatar, isEmptyNameField }) {
  const profileFormInputs: FormInputType[] = [
    {
      title: 'First Name',
      testid: 'profile-firstname-field',
      metaData: user.firstName,
      action: (e) =>
        isEmptyNameField({ value: e.target.value, attribute: 'firstName' }),
      placeholder: 'First Name'
    },
    {
      title: 'Last Name',
      testid: 'profile-lastname-field',
      metaData: user.lastName,
      action: (e) =>
        isEmptyNameField({ value: e.target.value, attribute: 'lastName' }),
      placeholder: 'Last Name'
    },
    {
      title: 'Email',
      testid: 'profile-email-field',
      metaData: user.email,
      action: (e) =>
        onChange({ attribute: 'email', value: e.target.value }),
      placeholder: 'Email',
      disabled: true
    },
    {
      component: (
        <div key="avatar">
          <AddEditAvatar
            setAvatar={setAvatar}
            setHasChangedAvatar={setHasChangedAvatar}
            avatar={avatar}
            editor={editor}
            hasChangedAvatar={hasChangedAvatar}
            firstName={user.firstName}
            lastName={user.lastName}
            setSaveMessage={setSaveMessage}
          />
        </div>
      )
    },
    {
      component: (
        <div key="shared-data" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', fontWeight: '600' }}>
          <div>Share profile to my workspace</div>
          <Switch
                checked={user?.shareData}
                onChange={() =>
                  onChange({ attribute: 'shareData', value: !user?.shareData })
                }
              />
        </div>
      )
    }
  ]

  const profileFormFields = profileFormInputs.map((field) => {
    if (field.component) {
      return field.component
    }
    return (
      <div key={field.title} className={field?.className}>
        <label>{field.title}</label>
        <input
          type="text"
          data-testid={field.testid}
          placeholder={field.placeholder}
          onChange={field.action}
          value={field.metaData}
          disabled={field.disabled}
          onBlur={field?.blur}
        />
      </div>
    )
  })

  return (
    <div className="profile-fields-container" data-testid="profile-settings-form">
      {profileFormFields}
    </div>
  )
}

export default ProfileSettings
