import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { sortUsersByStatusAndName } from './utilities'
import { useModal } from '../context/ModalContext'
import { logError } from './logger'
import {
  addPerson,
  addOrganizationCourse,
  createOrganization,
  createOrganizationCourse,
  deleteExerciseProgress,
  editContactNotes,
  endConnection,
  getBillableSeats,
  getWeeklyReport,
  getSpace,
  getSpaces,
  getPeople,
  getOrganizationCourses,
  getOrganizationRole,
  getOrganizationSubscription,
  getOrganizationUserCourses,
  getCourse,
  getPublicCourses,
  getConnection,
  getUser,
  postExerciseProgress,
  postSpaces,
  putSpaces,
  putPersonStatus,
  putUser,
  updateSpaceRoles,
  updateOrganization,
  updatePerson,
  updateConnection,
  getUsernameSearchResults,
  searchPeopleByEmail,
  putPromoCode,
  getPlans,
  getOrganizationReports,
  unfreezeOrganizationAndUpdatePaymentMethod,
  getOrganizationGoals,
  createOrganizationGoal,
  updateOrganizationGoal,
  deleteOrganizationGoal,
  getUserActions,
  startConnection,
  createAction,
  updateAction,
  deleteAction,
  updateConnectionAttendance,
  deleteConnectionAttendance,
  createDailyCheckin,
  getOrganizationDailyReports,
  getDailyReport,
  putDailycheckins,
  updateOrganizationRoleDailyCheckinAccess,
  updateOrganizationCourse,
  updateOrganizationRoleOrganizationCourseAccess,
  getExerciseListBySpace,
  getExerciseListByUser,
  getPeopleBySpace,
  resendInvite,
  getPeopleGallery,
  getCoursePresitge,
  putCoursePresitge,
  putPeopleStatus,
  getExercisesActionsForApproval,
  getOrganizationTags,
  putContactTag
} from './api'
import { toast } from 'sonner'

function useOrganizationReportsQuery (organizationId: number, options: any = {}) {
  return useQuery(['insights', organizationId], () => getOrganizationReports(organizationId), options)
}

function useOrganizationDailyReportsQuery (organizationId: number, options: any = {}) {
  return useQuery(['daily-insights', organizationId], () => getOrganizationDailyReports(organizationId), options)
}

function useWeeklyReportQuery (organizationId: number, startWeekLocal: number, endWeekLocal: number) {
  return useQuery(['weeklyInsights', organizationId], () =>
    getWeeklyReport({ organizationId, startWeekLocal, endWeekLocal })
  )
}

function useDailyReportQuery (organizationId: number, startDay, nextDay) {
  return useQuery(['dailyInsights', organizationId], () => getDailyReport({ startDay, nextDay }))
}

function useOrganizationGoalsQuery (organizationId: number, options: any = {}) {
  return useQuery(['goals', organizationId], () => getOrganizationGoals(organizationId), options)
}

function useOrganizationSubscriptionQuery (organizationId: number) {
  return useQuery(['organizations', organizationId, 'subscription'], () => getOrganizationSubscription(organizationId))
}

function useUserQuery (options: any = {}) {
  return useQuery(['user'], getUser, options)
}

function usePublicCoursesQuery () {
  return useQuery(['public_courses'], getPublicCourses)
}

function useCourseQuery (uid: string) {
  return useQuery(['courses', uid], () => getCourse(uid))
}

function usePlansQuery (uid?: string) {
  return useQuery(['courses', uid], getPlans)
}

function useSpacesQuery (organizationId: number, options: any = {}) {
  return useQuery(
    [`organization:${organizationId}`, 'spaces'],
    () => getSpaces({ filter: options?.filter, isArchived: options?.isArchived }),
    options)
}

function useSpaceQuery (id: number, options: any = {}) {
  return useQuery(['spaces', id], () => getSpace(id), options)
}

function usePeopleQuery (organizationId: number, options: any = {}) {
  return useQuery([`organization:${organizationId}`, 'people'], () => getPeople(), options)
}

export function usePeopleGalleryQuery (organizationId: number, options: any = {}) {
  return useQuery([`organization:${organizationId}`, 'people-gallery'], () => getPeopleGallery(), options)
}

function usePeopleBySpaceQuery (organizationId: number, spaceId?: number, options: any = {}) {
  return useQuery([`organization:${organizationId}`, 'people'], () => getPeopleBySpace(spaceId), options)
}

function useOrganizationRoleQuery (id: number, spaceId?: number, options: any = {}) {
  return useQuery(['organizationRole', id], () => getOrganizationRole({ id, spaceId }), options)
}

function useConnectionQuery (connectionUid: string, options: any = {}) {
  return useQuery(['connections', connectionUid], () => getConnection(connectionUid), options)
}

export function useOrganizationUserCoursePresitgeQuery (organizationRoleId: number, options: any = {}) {
  return useQuery(['course_prestige'], () => getCoursePresitge(organizationRoleId), options)
}

function useExerciseListBySpaceQuery (organizationRoleId: number, spaceId: number, options: any = {}) {
  return useQuery(
    ['exerciseList', organizationRoleId],
    () => getExerciseListBySpace({ organizationRoleId, spaceId }), options)
}

function useExerciseListByUserQuery (organizationRoleId: number, userId: number, options: any = {}) {
  return useQuery(
    ['exerciseList', organizationRoleId],
    () => getExerciseListByUser({ organizationRoleId, userId }), options)
}

export function useOrganizationCourseQuery (options: any = {}) {
  return useQuery(['courses'], getOrganizationCourses, options)
}

export function useOrganizationUserCourseQuery (organizationRoleId: number, options: any = {}) {
  return useQuery(['user_courses'], () => getOrganizationUserCourses(organizationRoleId), options)
}

function useBillableSeatsQuery (currentOrganizationId: number, options: any = {}) {
  return useQuery(['billable_seats', currentOrganizationId], getBillableSeats, options)
}

function useOrganizationTagsQuery ({ organizationId, options }) {
  return useQuery(['organization_tags', organizationId], getOrganizationTags, options)
}

function useUsernameSearchQuery (searchInput: string, spaceId?: number, options: any = {}) {
  return useQuery(
    ['username_search', searchInput],
    () => getUsernameSearchResults({ username: searchInput, spaceId }), options)
}

function useSearchPeopleByEmailQuery (email: string, spaceId?: number, options: any = {}) {
  return useQuery(['peopleSearch', email], () => searchPeopleByEmail({ email, spaceId }), options)
}

function useActionsQuery (organizationRoleId: number, spaceId?: number, options: any = {}) {
  return useQuery(['actions', organizationRoleId], () => getUserActions({ organizationRoleId, spaceId }), options)
}

function useExercisesActionsApprovalQuery (courseVersionId: number, options: any = {}) {
  return useQuery(['exercises_action_approval', courseVersionId], () => getExercisesActionsForApproval(courseVersionId), options)
}

function useEditSpaceRolesMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(updateSpaceRoles, {
    onSuccess: async (response, request) => {
      await queryClient.cancelQueries([`organization:${request.organizationId}`, 'people'])
      const previousPeople = queryClient.getQueryData<any>([`organization:${request.organizationId}`, 'people']) || []
      const nextPeople = previousPeople?.map((person) => (person?.id === response?.id ? response : person))
      queryClient.setQueryData([`organization:${request.organizationId}`, 'people'], nextPeople)
      clearModal()
      toast.success('Space role updated successfully')
      return { previousPeople }
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update space role')
    }
  })
}

function useEditContactSpaceRolesMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(updateSpaceRoles, {
    onSuccess: async (response, request) => {
      await queryClient.cancelQueries(['organizationRole', request?.organizationRoleId])
      queryClient.setQueryData(['organizationRole', request?.organizationRoleId], response)
      clearModal()
      toast.success('Role updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update role')
    }
  })
}

function useEditPersonMutation () {
  const queryClient = useQueryClient()
  return useMutation(updatePerson, {
    onSuccess: async (response, request) => {
      await queryClient.cancelQueries([
        [`organization:${request.organizationId}`, 'people'],
        ['organizationRole', parseInt(response?.id)]]
      )
      const previousPeople = queryClient.getQueryData<any>([`organization:${request.organizationId}`, 'people']) || []
      const nextPeople = previousPeople?.map((person) => (person?.id === response?.id ? response : person))
      queryClient.setQueryData([`organization:${request.organizationId}`, 'people'], nextPeople)
      queryClient.setQueryData(['organizationRole', parseInt(response?.id)], response)
      await queryClient.invalidateQueries({ queryKey: ['billable_seats', request.organizationId] })
      await queryClient.invalidateQueries({ queryKey: ['organizationRole', parseInt(response?.id)] })
      toast.success('Person updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update person')
    }
  })
}

function useAddPersonMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(addPerson, {
    onSuccess: async (response, request) => {
      const previousPeople = queryClient.getQueryData<any>([`organization:${request.organizationId}`, 'people']) || []
      const nextPeople = [...previousPeople, response].sort(sortUsersByStatusAndName)
      await queryClient.cancelQueries([`organization:${request.organizationId}`, 'people'])
      queryClient.setQueryData([`organization:${request.organizationId}`, 'people'], nextPeople)
      await queryClient.invalidateQueries({ queryKey: ['billable_seats', request.organizationId] })
      clearModal()
      toast.success('Person added successfully')
      return response
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to add person')
    }
  })
}

function useResendInvitationMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(resendInvite, {
    onSuccess: async (response, request) => {
      const previousPeople = queryClient.getQueryData<any>([`organization:${request.organizationId}`, 'people']) || []
      const nextPeople = [...previousPeople, response].sort(sortUsersByStatusAndName)
      await queryClient.cancelQueries([`organization:${request.organizationId}`, 'people'])
      queryClient.setQueryData([`organization:${request.organizationId}`, 'people'], nextPeople)
      await queryClient.invalidateQueries({ queryKey: ['billable_seats', request.organizationId] })
      clearModal()
      toast.success('Invitation resent successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to resend invitation')
    }
  })
}

function useEditPersonStatusMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(putPersonStatus, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([`organization:${response.organizationId}`, 'people'])
      clearModal()
      toast.success('Status updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update status')
    }
  })
}

function useEditPeopleStatusMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(putPeopleStatus, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([`organization:${response.organizationId}`, 'people'])
      clearModal()
      toast.success('Statuses updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update statuses')
    }
  })
}

function useEndConnectionMutation () {
  return useMutation(endConnection, {
    onError: (error) => {
      logError(error)
      toast.error('Failed to end connection')
    }
  })
}

function useUpdateConnectionMutation () {
  return useMutation(updateConnection, {
    onError: (error) => {
      logError(error)
      toast.error('Failed to update connection')
    }
  })
}

function useAddSpaceMutation () {
  const queryClient = useQueryClient()
  return useMutation(postSpaces, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(['user'])
      toast.success('Space added successfully')
      return response
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to add space')
    }
  })
}

function useEditPersonNotesMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(editContactNotes, {
    onSuccess: async (response, request: any) => {
      await queryClient.cancelQueries([`organization:${request?.organizationId}`, 'people'])
      const previousPeople = await queryClient.getQueryData<any>([`organization:${request?.organizationId}`, 'people'])
      const updatedPeople = previousPeople?.map((person) =>
        person?.contact?.id === request.contactId
          ? { ...person, contact: { ...person.contact, notes: request.notes } }
          : person
      )

      queryClient.setQueryData([`organization:${request?.organizationId}`, 'people'], updatedPeople)
      clearModal()
      toast.success('Notes updated successfully')
      return { previousPeople }
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update notes')
    }
  })
}

function useEditContactNotesMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(editContactNotes, {
    onSuccess: async (response, request: any) => {
      await queryClient.cancelQueries(['organizationRole', request?.organizationRoleId])
      const previousPerson = await queryClient.getQueryData<any>(['organizationRole', request?.organizationRoleId])
      const updatedPerson = { ...previousPerson, contact: { ...previousPerson?.contact, notes: request.notes } }

      queryClient.setQueryData(['organizationRole', request?.organizationRoleId], updatedPerson)
      clearModal()
      toast.success('Notes updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update notes')
    }
  })
}

function useEditSpaceMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(putSpaces, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(['user'])
      await queryClient.cancelQueries(['spaces', response?.id])
      const previousSpace = queryClient.getQueryData(['spaces', response?.id])
      queryClient.setQueryData(['spaces', response?.id], response)
      await queryClient.invalidateQueries(['organizationRole'])
      clearModal()
      toast.success('Space updated successfully')
      return { previousSpace }
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update space')
    }
  })
}

function useDeleteExerciseProgressMutation () {
  const queryClient = useQueryClient()
  return useMutation(deleteExerciseProgress, {
    onMutate: async (request: any) => {
      await queryClient.cancelQueries(['exerciseList', parseInt(request.organizationRoleId)])
    },
    onSuccess: async (response, request: any) => {
      await queryClient.invalidateQueries(['exerciseList', parseInt(request.organizationRoleId)])
      await queryClient.invalidateQueries(['connections', request?.connectionUid])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete exercise progress')
    }
  })
}

function useCreateExerciseProgressMutation () {
  const queryClient = useQueryClient()
  return useMutation(postExerciseProgress, {
    onSuccess: async (response, request: any) => {
      await queryClient.invalidateQueries(['exerciseList', parseInt(request.organizationRoleId)])
      await queryClient.invalidateQueries(['connections', request?.connectionUid])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create exercise progress')
    }
  })
}

function useCreateOrganizationCoursesMutation () {
  return useMutation(createOrganizationCourse, {
    onError: (error) => {
      logError(error)
      toast.error('Failed to create organization course')
    }
  })
}

function useCreateOrganizationMutation () {
  return useMutation(createOrganization, {
    onSuccess: () => {
      toast.success('Organization successfully created')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create organization')
    }
  })
}

function useUpdateOrganizationMutation () {
  return useMutation(updateOrganization, {
    onSuccess: () => {
      toast.success('Organization successfully updated')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update organization')
    }
  })
}

function useUserMutation () {
  const queryClient = useQueryClient()
  return useMutation(putUser, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['user'])
      const previousUser = queryClient.getQueryData(['user'])
      queryClient.setQueryData(['user'], response)
      return { previousUser }
    }
  })
}

function useUpdatePromoCodeMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(putPromoCode, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries({ queryKey: ['organizations', response?.appliedBy, 'subscription'] })
      clearModal()
      toast.success('Promo code updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update promo code')
    }
  })
}

function useUpdateOrganizationAndCreateSubscriptionMutation () {
  const queryClient = useQueryClient()
  return useMutation(unfreezeOrganizationAndUpdatePaymentMethod, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries(['user'])
      await queryClient.invalidateQueries({ queryKey: ['organizations', request?.organizationId, 'subscription'] })
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update organization subscription')
    }
  })
}

function useCreateConnectMutation () {
  return useMutation(startConnection, {
    onError: (error) => {
      logError(error)
      toast.error('Failed to create connect')
    }
  })
}

function useDeleteOrganizationGoalMutation () {
  const queryClient = useQueryClient()
  return useMutation(deleteOrganizationGoal, {
    onMutate: async (request: any) => {
      await queryClient.cancelQueries(['goals', request.organizationId])
    },
    onSuccess: (response, request: any) => {
      const previousOrgGoals = queryClient.getQueryData<any>(['goals', request.organizationId])
      const updateOrgGoals = previousOrgGoals?.filter((goal) => goal.id !== request.goalId)
      queryClient.setQueryData(['goals', request.organizationId], updateOrgGoals)
      toast.success('Goal deleted successfully')
      return { previousOrgGoals }
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete goal')
    }
  })
}

function useCreateOrganizationGoalMutation () {
  const queryClient = useQueryClient()
  return useMutation(createOrganizationGoal, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['goals', response.organizationId])
      const previousOrgGoals = queryClient.getQueryData<any>(['goals', response.organizationId])
      queryClient.setQueryData(['goals', response.organizationId], [...previousOrgGoals, response])
      toast.success('Goal created successfully')
      return { previousOrgGoals }
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create goal')
    }
  })
}

function useUpdateOrganizationGoalMutation () {
  const queryClient = useQueryClient()
  return useMutation(updateOrganizationGoal, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['goals', response.organizationId])
      const previousOrgGoals = queryClient.getQueryData<any>(['goals', response.organizationId])
      const updateOrgGoals = previousOrgGoals?.map((goal) => (goal.id === response.id ? response : goal))
      queryClient.setQueryData(['goals', response.organizationId], updateOrgGoals)
      toast.success('Goal updated successfully')
      return { previousOrgGoals }
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update goal')
    }
  })
}

function useDeleteActionMutation () {
  const queryClient = useQueryClient()
  return useMutation(deleteAction, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries(['actions', request?.organizationRoleId])
      await queryClient.invalidateQueries(['connections', request?.uid])
      toast.success('Action deleted successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete action')
    }
  })
}

function useCreateActionMutation () {
  const queryClient = useQueryClient()
  return useMutation(createAction, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries(['actions', request?.organizationRoleId])
      await queryClient.invalidateQueries(['connections', request?.uid])
      toast.success('Action created successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create action')
    }
  })
}

function useUpdateActionMutation () {
  const queryClient = useQueryClient()
  return useMutation(updateAction, {
    onSuccess: async (response, request: any) => {
      await queryClient.cancelQueries(['actions', request?.organizationRoleId])
      await queryClient.invalidateQueries(['actions', request?.organizationRoleId])
      if (request?.uid) {
        await queryClient.invalidateQueries(['connections', request?.uid])
      }
      toast.success('Action updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update action')
    }
  })
}

function useUpdateConnectionAttendanceMutation () {
  const queryClient = useQueryClient()
  return useMutation(updateConnectionAttendance, {
    onSuccess: async (response, request) => {
      await queryClient.cancelQueries(['connections', request?.uid])
      await queryClient.invalidateQueries(['connections', request?.uid])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update attendance')
    }
  })
}

function useDeleteConnectionAttendanceMutation () {
  const queryClient = useQueryClient()
  return useMutation(deleteConnectionAttendance, {
    onSuccess: async (response, request) => {
      await queryClient.cancelQueries(['connections', request?.uid])
      await queryClient.invalidateQueries(['connections', request?.uid])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete attendance')
    }
  })
}

function useCreateDailyCheckinMutation () {
  const queryClient = useQueryClient()
  return useMutation(createDailyCheckin, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['actions'])
      await queryClient.invalidateQueries(['connections'])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create daily checkin')
    }
  })
}

function useUpdateDailyCheckinMutation () {
  const queryClient = useQueryClient()
  return useMutation(putDailycheckins, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['checkin'])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update daily checkin')
    }
  })
}

function useUpdateOrganizationRoleDailyCheckinAccessMutation () {
  return useMutation(updateOrganizationRoleDailyCheckinAccess, {
    onSuccess: () => {
      toast.success('Daily checkin access updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update daily checkin access')
    }
  })
}

function useUpdateOrganizationCourseMutation () {
  const queryClient = useQueryClient()
  return useMutation(updateOrganizationCourse, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['courses'])
      await queryClient.invalidateQueries(['courses'])
      toast.success('Organization course updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update organization courses')
    }
  })
}

function useUpdateOrganizationCourseAccessMutation () {
  const queryClient = useQueryClient()
  return useMutation(updateOrganizationRoleOrganizationCourseAccess, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['courses'])
      await queryClient.invalidateQueries(['courses'])
      toast.success('Organization course access updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update organization course access')
    }
  })
}

function useAddOrganizationCourseMutation () {
  const queryClient = useQueryClient()
  return useMutation(addOrganizationCourse, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['public_courses'])
      await queryClient.invalidateQueries({ queryKey: ['public_courses'] })
      toast.success('Course added successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to add course')
    }
  })
}

function useEditPersonCoursePrestigeMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(putCoursePresitge, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries(['user_courses'])
      await queryClient.invalidateQueries(['course_prestige'])
      await queryClient.invalidateQueries(['exerciseList', request.organizationRoleId])
      clearModal()
      toast.success('Course reset successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to reset course')
    }
  })
}

function useUpdateContactTagsMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(putContactTag, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(['organization_tags'])
      await queryClient.invalidateQueries([`organization:${response.organizationId}`, 'people'])
      clearModal()
      toast.success('Person tags updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update person tags')
    }
  })
}

export {
  useAddSpaceMutation,
  useAddOrganizationCourseMutation,
  useAddPersonMutation,
  useBillableSeatsQuery,
  useQueryClient,
  useWeeklyReportQuery,
  useCreateExerciseProgressMutation,
  useCreateOrganizationMutation,
  useCreateOrganizationCoursesMutation,
  useDeleteExerciseProgressMutation,
  useEditContactSpaceRolesMutation,
  useEditContactNotesMutation,
  useEditSpaceMutation,
  useEditSpaceRolesMutation,
  useEditPersonNotesMutation,
  useEditPersonStatusMutation,
  useEditPersonMutation,
  useEndConnectionMutation,
  useExerciseListByUserQuery,
  useExerciseListBySpaceQuery,
  useSpaceQuery,
  useSpacesQuery,
  usePeopleQuery,
  usePeopleBySpaceQuery,
  usePlansQuery,
  useOrganizationRoleQuery,
  useOrganizationSubscriptionQuery,
  useCourseQuery,
  usePublicCoursesQuery,
  useConnectionQuery,
  useUpdateConnectionMutation,
  useUserMutation,
  useUserQuery,
  useUpdateOrganizationMutation,
  useUsernameSearchQuery,
  useSearchPeopleByEmailQuery,
  useUpdatePromoCodeMutation,
  useOrganizationReportsQuery,
  useUpdateOrganizationAndCreateSubscriptionMutation,
  useCreateConnectMutation,
  useOrganizationGoalsQuery,
  useUpdateOrganizationGoalMutation,
  useCreateOrganizationGoalMutation,
  useDeleteOrganizationGoalMutation,
  useActionsQuery,
  useCreateActionMutation,
  useUpdateActionMutation,
  useDeleteActionMutation,
  useUpdateConnectionAttendanceMutation,
  useDeleteConnectionAttendanceMutation,
  useCreateDailyCheckinMutation,
  useOrganizationDailyReportsQuery,
  useDailyReportQuery,
  useUpdateDailyCheckinMutation,
  useUpdateOrganizationRoleDailyCheckinAccessMutation,
  useUpdateOrganizationCourseMutation,
  useUpdateOrganizationCourseAccessMutation,
  useResendInvitationMutation,
  useEditPersonCoursePrestigeMutation,
  useEditPeopleStatusMutation,
  useExercisesActionsApprovalQuery,
  useOrganizationTagsQuery,
  useUpdateContactTagsMutation
}
