import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import { useOrganizationGoalsQuery } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import { logError } from '../../shared/logger'
import EmptyHero from '../../components/EmptyHero'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import goalsIllustration from '../../assets/goals.svg'
import CreateOrEditGoal from '../../modals/CreateOrEditGoal'
import GoalSettingsMenu from '../../components/GoalSettingsMenu'
import LoadingScreen from '../../components/LoadingScreen'
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded'
import './index.css'
import { Entity } from '../../shared/enums'

type ActionType = {
  action: () => void
  actionTitle: string
  actionClassName?: string
}

function Goals () {
  const { currentUser } = useAuthContext()
  const { clearModal, makeModal } = useModal()

  const { isLoading, isError, data: goals, error } = useOrganizationGoalsQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser })

  const isAdminOrOwner = isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)

  const getSubnavAction = () => {
    const actions: ActionType[] = []

    if (isAdminOrOwner) {
      actions.push(
        {
          action: () => makeModal({
            modal: <CreateOrEditGoal
            clearModal={clearModal}
            />,
            title: `${Entity.Workspace} Goal`
          }),
          actionTitle: 'Create Goal',
          actionClassName: 'secondary'
        })
    }
    return actions
  }

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
  }

  const goalDisplay = goals?.map((goal) => {
    return (
      <div className="goal-container" key={goal?.id}>
        <div className="title"><AdjustRoundedIcon /> {goal?.title}</div>
        <div className="goal-button-container">
          <GoalSettingsMenu
            goalId={goal?.id}
            title={goal?.title}
          />
        </div>
      </div>
    )
  })

  const action = [{
    onClick: () => makeModal({
      modal: <CreateOrEditGoal
      clearModal={clearModal}
      />,
      title: `${Entity.Workspace} Goal`
    }),
    actionTitle: 'Create Goal'
  }]

  return (
    <>
      <SecondaryNavBar title="Goals" onClickActions={getSubnavAction()}/>
      {goals && goals?.length >= 1 &&
        <div className="goals-container">
          {goalDisplay}
        </div>
      }
      {(!goals || goals?.length < 1) &&
        <>
          { isAdminOrOwner
            ? <EmptyHero
                image={goalsIllustration}
                title="Goals"
                action={action}
                description={`Goals help your entire ${Entity.Workspace} get aligned and go after great things together!`}
              />
            : <EmptyHero
                image={goalsIllustration}
                title="Goals"
                description={`Goals help your entire ${Entity.Workspace} get aligned and go after great things together!`}
              />
          }
        </>
      }
    </>
  )
}

export default Goals
