const roles = {
  owner: 'OWNER',
  admin: 'ADMIN',
  leader: 'LEADER',
  member: 'MEMBER',
  guest: 'GUEST'
}

const rolesDisplayMap = {
  GUEST: 'Guest',
  MEMBER: 'Member',
  LEADER: 'Leader',
  ADMIN: 'Admin',
  OWNER: 'Owner'
}

const billableRoles = [roles.member, roles.leader, roles.admin, roles.owner]

export enum Entity {
  Workspace = 'Workspace',
  Space = 'Space',
}

export {
  roles,
  rolesDisplayMap,
  billableRoles
}
