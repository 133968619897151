import { useNavigate } from 'react-router-dom'
import { formatConnectionDate } from '../../shared/utilities'
import { useModal } from '../../context/ModalContext'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import StartOrScheduleConnection from '../../modals/StartOrScheduleConnection'
import './index.css'
import { Connection, Space } from '../../types'
import { parseDateTime } from '../../shared/dates'

interface ConnectionsUpcomingProps {
  connections: Connection[],
  space: Space
 }

function ConnectionsUpcoming ({ connections, space }: ConnectionsUpcomingProps) {
  const navigate = useNavigate()
  const { makeModal } = useModal()
  const { currentUser } = useAuthContext()
  const isSpaceLeaderOrAbove = isAuthorized(getSpaceLeaderPolicy(space?.id, currentUser?.currentOrganizationId), currentUser)

  if (connections.length === 0) {
    return null
  }

  function rescheduleConnection (connection) {
    makeModal({
      modal: <StartOrScheduleConnection space={space} connection={connection}/>,
      title: `Reschedule Connect for ${space?.name}`
    })
  }

  return (
    <div className="connection-in-progress-history-list">
      <h3>Upcoming Connects</h3>
      {connections.sort((a, b) => parseDateTime(a?.startTime) - parseDateTime(b?.startTime)).map(connection => (
        <div className="connection-item" key={connection.uid}>
          <div className="connection-title">
            {formatConnectionDate(connection.startTime)}
          </div>
          <div className="connection-in-progress-settings-container">
            {isSpaceLeaderOrAbove && <div className="button secondary reschedule" onClick={() => rescheduleConnection(connection)}>
              Reschedule
            </div>}
            <div className="button secondary" onClick={() => navigate(`/connections/${connection.uid}`)}>
              Prepare
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ConnectionsUpcoming
