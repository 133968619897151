import { OrganizationRole } from '../../types'
import OrganizationProfile from '../../components/OrganizationProfile'

interface ViewOrganizationProfileProps {
  person: OrganizationRole
}

function ViewOrganizationProfile ({ person }: ViewOrganizationProfileProps) {
  return (
    <div className="modal-content">
      <OrganizationProfile person={person}/>
    </div>
  )
}

export default ViewOrganizationProfile
