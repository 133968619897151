export const parseDate = (date: string | null | undefined): Date | null => {
  if (date) {
    return new Date(date)
  } else {
    return null
  }
}

export const formattedDate = (date: string | null | undefined): string => {
  const dt = parseDate(date)
  if (!dt) return ''
  // Have to add 1 to the month because javascript is stupid
  return dt.getFullYear() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0')
}

export const parseDefaultedDate = (date: string | null | undefined): Date => {
  if (date) {
    return new Date(date)
  } else {
    return new Date()
  }
}

export const parseDateTime = (date: string | null | undefined): number => {
  if (date) {
    return new Date(date).getTime()
  } else {
    return 0
  }
}

export const currentDateTime = (): number => {
  return new Date().getTime()
}
