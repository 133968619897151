import EmptyHero from '../../../components/EmptyHero'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import programsSvg from '../../../assets/programs.svg'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { useOrganizationCourseQuery, useUpdateOrganizationCourseMutation } from '../../../shared/queryHooks'
import { Skeleton, Switch } from '@mui/material'
import { PuffLoader } from 'react-spinners'
import { sortOrgCourses } from '../../../shared/utilities'
import { useModal } from '../../../context/ModalContext'
import { logError } from '../../../shared/logger'
import { useState } from 'react'
import EditOrganizationRolesOrganizationCourse from '../../../modals/EditOrganizationRolesOrganizationCourse'
import OrganizationCourseReview from '../../../modals/OrganizationCourseReview'
import './index.css'

function WorkspaceSettingsCourses () {
  const { makeModal } = useModal()
  const [searchParms, setSearchParms] = useState('')
  const editOrganizationCourse = useUpdateOrganizationCourseMutation()
  const { isError, isLoading, data: orgCourses, error } = useOrganizationCourseQuery()

  async function toggleOrgCourse ({ id, enabled, requiresReview, actionsApproved }) {
    return await editOrganizationCourse.mutateAsync({ id, enabled, access: [], requiresReview, actionsApproved })
  }

  function editOrganizationRoleOrganizationCourseModal (organizationCourse) {
    makeModal({
      modal: <EditOrganizationRolesOrganizationCourse organizationCourse={organizationCourse} />,
      title: `Members enrolled in ${organizationCourse?.courseVersion?.course?.name ?? 'ConnectBetter'} v${
        organizationCourse?.courseVersion?.version ?? 1
      }.0.0`
    })
  }

  function reviewRequiredCourseActions (organizationCourse) {
    makeModal({
      modal: <OrganizationCourseReview id={organizationCourse?.id} courseVersionId={organizationCourse?.courseVersionId} access={organizationCourse?.organizationRoleAccess} enabled={organizationCourse?.enabled} />,
      title: `Review for ${organizationCourse?.courseVersion?.course?.name ?? 'ConnectBetter'} v${
        organizationCourse?.courseVersion?.version ?? 1
      }.0.0`
    })
  }

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar title="Courses" />
        <div className="billing-container-wrapper">
          <div className="program-card" style={{ flexDirection: 'column' }}>
            <Skeleton width="50%" height={22} />
            <Skeleton width="100%" height={20} />
          </div>
        </div>
      </>
    )
  }

  if (isError) {
    logError(error)
  }

  const filteredCourses = sortOrgCourses(orgCourses)
    ?.filter((course) =>
      course?.courseVersion?.course?.name?.toLowerCase().includes(searchParms.toLowerCase())
    )

  const groupedCourses: { [key: string]: any[] } = filteredCourses.reduce((acc, orgCourse) => {
    const programUid = orgCourse?.courseVersion?.course?.program?.uid
    if (!acc[programUid]) {
      acc[programUid] = []
    }
    acc[programUid].push(orgCourse)
    return acc
  }, {})

  return (
    <>
      <SecondaryNavBar title="Courses" />
      <div className="billing-container-wrapper org-nav-settings">
        <div className="spaces-search settings-spaces-search" style={{ marginBottom: '1rem' }}>
          <input
            type="text"
            placeholder="Search Courses..."
            onChange={(e) => setSearchParms(e.target.value)}
            value={searchParms}
          />
        </div>
        {Object.entries(groupedCourses).map(([programUid, courses]) => (
            <div key={programUid}>
              <div className="program-name-title">
                <h3>{courses[0]?.courseVersion?.course?.program?.name}</h3>
                <hr />
              </div>
              {courses.map((orgCourse) => (
                <div key={orgCourse?.id} className="program-card">
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div className="program-logo">{orgCourse?.courseVersion?.course?.name[0]}</div>
                      <div>
                        <div className="program-name">
                          {orgCourse?.courseVersion?.course?.name} -
                          <span style={{ fontStyle: 'italic' }}>
                            {orgCourse?.courseVersion?.course?.program?.name}
                          </span>
                        </div>
                        <div className="publisher-name">
                          {orgCourse?.courseVersion?.course?.program?.publisherName}
                        </div>
                      </div>
                    </div>
                    <div className="program-subscribed">
                      <CheckRoundedIcon /> Subscribed v{orgCourse.courseVersion.version}.0.0
                    </div>
                  </div>
                  <div className="component-toggle-container">
                    <div className="daily-checkin-settings-container">
                      {orgCourse?.requiresReview && <div className="button danger" onClick={() => reviewRequiredCourseActions(orgCourse)}>Review Required</div>}
                      {editOrganizationCourse.isLoading
                        ? (
                          <PuffLoader color="#fff" size={38} />
                          )
                        : (
                            <Switch
                              checked={orgCourse?.enabled}
                              onChange={() => toggleOrgCourse({ id: orgCourse?.id, enabled: !orgCourse?.enabled, requiresReview: orgCourse?.courseVersion?.reviewRequired, actionsApproved: orgCourse?.actionsApproved })}
                            />
                          )}
                      {orgCourse?.enabled && !editOrganizationCourse.isLoading && (
                        <div
                          onClick={() => editOrganizationRoleOrganizationCourseModal(orgCourse)}
                          className="button secondary icon daily-checkin-person-icon"
                        >
                          <PersonAddIcon />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
        ))}
        {filteredCourses?.length === 0 && (
          <div>No items found</div>
        )}
      </div>
      {orgCourses?.length === 0 && (
        <EmptyHero
          image={programsSvg}
          title="Get courses"
          description={`${
            orgCourses?.length === 0 ? "You don't have any courses setup yet." : 'Want to get additional courses?'
          } Our marketplace will be available soon for you to browse public courses!`}
        />
      )}
    </>
  )
}

export default WorkspaceSettingsCourses
