import EmptyHero from '../../components/EmptyHero'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import learnSvg from '../../assets/learn.svg'
import { Entity } from '../../shared/enums'

function Learn () {
  const avodaRedirectAction = () => {
    window.open('https://avoda.co/learn', '_blank')
  }

  const action = [{
    onClick: avodaRedirectAction,
    actionTitle: 'Learn More'
  }]

  return (
    <>
      <SecondaryNavBar title="Learn"/>
      <EmptyHero
        image={learnSvg}
        title="Learn"
        description={`Interested in learning how to be an effective coach and mentor? Or how your ${Entity.Workspace} might best setup up for success to facilitate learning?`}
        action={action}/>
    </>
  )
}

export default Learn
