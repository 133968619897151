import { logError } from '../../../shared/logger'
import { useOrganizationGoalsQuery } from '../../../shared/queryHooks'
import Addon from '../Addon'
import goalsIllustration from '../../../assets/goals.svg'
import EmptyHero from '../../../components/EmptyHero'
import LoadingScreen from '../../../components/LoadingScreen'
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded'
import './index.css'
import { User } from '../../../types'
import { Entity } from '../../../shared/enums'

interface GoalsAddonProps {
  pinned: boolean
  setAsPinned: () => void
  currentUser: User
}

function GoalsAddon ({ pinned, setAsPinned, currentUser }: GoalsAddonProps) {
  const { isLoading, isError, data: goals, error } = useOrganizationGoalsQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser })

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
    return (
      <LoadingScreen />
    )
  }

  const goalDisplay = goals?.map((goal) => {
    return (
      <div className="connect-goal-container" key={goal?.id}>
        <div className="connect-goal-title"><AdjustRoundedIcon /> {goal?.title}</div>
      </div>
    )
  })

  return (
    <Addon title="Goals" className="goals" pinned={pinned} setAsPinned={setAsPinned}>
      {goals?.length >= 1
        ? <div className="connect-goals-container">
            {goalDisplay}
          </div>
        : <EmptyHero
          image={goalsIllustration}
          title="Goals"
          description={`Goals help your entire ${Entity.Workspace} get aligned and go after great things together!`}
        />
      }
    </Addon>
  )
}

export default GoalsAddon
