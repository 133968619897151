import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/CloseRounded'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import './index.css'

function TrialEndingBanner ({ currentUser, displayTrialBanner, setDisplayTrialBanner }) {
  const navigate = useNavigate()
  const isAdminOrOwner = isAuthorized(getOrganizationAdminPolicy(currentUser.currentOrganizationId), currentUser)

  if (!displayTrialBanner || currentUser?.daysUntilTrialEndWithOutPaymentMethod === null || currentUser?.daysUntilTrialEndWithOutPaymentMethod === undefined || !isAdminOrOwner) {
    return null
  }

  return (
      <div className="trial-banner">
        <div className="trial-content">
          <div className="trial-text">{`Your free trial ends in ${currentUser?.daysUntilTrialEndWithOutPaymentMethod} days. Add a payment method to keep your workspace.`}</div>
          <div onClick={() => navigate('organizations/settings/billing')} className="button payment-button">Add Card</div>
        </div>
        <CloseIcon onClick={() => setDisplayTrialBanner(false)} />
      </div>
  )
}

export default TrialEndingBanner
