import SecondaryNavBar from '../../../components/SecondaryNavBar'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import EditOrganizationRolesDailyCheckin from '../../../modals/EditOrganizationRolesDailyCheckin'
import { useUpdateOrganizationMutation, useUpdateDailyCheckinMutation } from '../../../shared/queryHooks'
import { getCurrentOrganization } from '../../../shared/utilities'
import { useAuthContext } from '../../../context/AuthContext'
import { useQueryClient } from '@tanstack/react-query'
import { PuffLoader } from 'react-spinners'
import { Switch } from '@mui/material'
import { useState, useEffect } from 'react'
import { logError } from '../../../shared/logger'
import { useModal } from '../../../context/ModalContext'
import { getOrganizationRoleDailyCheckins } from '../../../shared/api'
import './index.css'
import { Checkin } from '../../../types'
import { parsedErrorMessage } from '../../../shared/errors'

function OrganizationSettingsActions () {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const queryClient = useQueryClient()
  const editOrganization = useUpdateOrganizationMutation()
  const editDailyCheckins = useUpdateDailyCheckinMutation()
  const { makeModal } = useModal()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [saveMessage, setSaveMessage] = useState<string>('')
  const [saveDayMessage, setSaveDayMessage] = useState<string>('')
  const [errorDayMessage, setErrorDayMessage] = useState<string>('')
  const [dailyCheckins, setDailyCheckins] = useState<Checkin[]>([])
  const [dailyCheckinDaysOfWeek, setDailyCheckinDaysOfWeek] = useState<any>(currentOrganization?.dailyCheckinDaysOfWeek ?? {})

  async function getDailyCheckins () {
    return await queryClient.fetchQuery(['checkin'], () => getOrganizationRoleDailyCheckins())
  }

  useEffect(() => {
    const fetchDailyCheckins = async () => {
      const dailyCheckinData = await getDailyCheckins()
      setDailyCheckins(dailyCheckinData)
    }
    void fetchDailyCheckins()
  }, [])

  function handleChange (id: number, description: string) {
    setErrorMessage('')
    setSaveMessage('')
    setDailyCheckins(prevState => (prevState.map(state => state.id === id ? { ...state, description } : state)))
  }

  function handleKeyChange (key: string) {
    setSaveDayMessage('')
    const dailyCheckinDaysObj = JSON.parse(dailyCheckinDaysOfWeek)
    const newDailyCheckinDayPreferences = { ...dailyCheckinDaysObj, [key]: !dailyCheckinDaysObj[key] }
    setDailyCheckinDaysOfWeek(JSON.stringify(newDailyCheckinDayPreferences))
  }

  async function toggleDailyCheckins (dailyCheckin: boolean) {
    await editOrganization.mutateAsync({
      name: currentOrganization?.name ?? '',
      organizationId: currentUser?.currentOrganizationId ?? 0,
      memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
      logoUrl: currentOrganization?.logoUrl ?? '',
      dailyCheckin,
      dailyCheckinDaysOfWeek: currentOrganization?.dailyCheckinDaysOfWeek ?? '{}',
      editConnection: currentOrganization?.editConnection,
      defaultSpace: currentOrganization?.defaultSpace ?? false,
      shareContactData: currentOrganization?.shareContactData ?? false
    })
    await queryClient.invalidateQueries()
  }

  async function updateDailyCheckinDays () {
    try {
      await editOrganization.mutateAsync({
        name: currentOrganization?.name ?? '',
        organizationId: currentUser?.currentOrganizationId ?? 0,
        memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
        logoUrl: currentOrganization?.logoUrl ?? '',
        dailyCheckin: currentOrganization?.dailyCheckin,
        dailyCheckinDaysOfWeek,
        editConnection: currentOrganization?.editConnection,
        defaultSpace: currentOrganization?.defaultSpace ?? false,
        shareContactData: currentOrganization?.shareContactData ?? false
      })
      await queryClient.invalidateQueries()
      setSaveDayMessage('Your changes have been saved')
    } catch (error) {
      logError(error)
      setErrorDayMessage(parsedErrorMessage(error))
    }
  }

  async function submitDailyCheckins () {
    const isEmpty = dailyCheckins?.filter(checkin => checkin?.description.trim() === '')
    if (isEmpty?.length > 0) {
      setErrorMessage('Please fill out all check-in descriptions before submitting')
      return
    }

    try {
      await editDailyCheckins.mutateAsync(dailyCheckins)
      setSaveMessage('Your changes have been saved')
    } catch (error) {
      logError(error)
      setErrorMessage(parsedErrorMessage(error))
    }
  }

  function editOrganizationRoleDailyCheckinAccessModal () {
    makeModal({
      modal: <EditOrganizationRolesDailyCheckin/>,
      title: 'Members that Receive the Daily Check-in'
    })
  }

  const dailyCheckinFormFields = dailyCheckins.filter(checkin => checkin.responseType === 'TEXT').map(checkin => {
    return (
      <div key={checkin.id} className='daily-checkin-form-input'>
        <input
          type="text"
          placeholder="Daily check-in description..."
          onChange={(e) => handleChange(checkin.id, e.target.value)}
          value={checkin.description}
        />
      </div>
    )
  })

  const getDailyCheckinDaysOfWeek = () => {
    try {
      return new Array(JSON.parse(dailyCheckinDaysOfWeek)) ?? []
    } catch (e) {
      return []
    }
  }

  const daysOfTheWeekDisplay = getDailyCheckinDaysOfWeek().map(day => {
    const daysOfWeek = Object.keys(day)
    const dayDisplay = daysOfWeek.map(data => {
      return (
        <div key={data} className={(JSON.parse(dailyCheckinDaysOfWeek))[data] ? 'day-selected' : 'day-unselected'} onClick={() => handleKeyChange(data)}>{data.toUpperCase()}</div>
      )
    })
    return dayDisplay
  })

  return (
    <>
      <SecondaryNavBar title="Actions"/>
      <div className="profile-settings-wrapper org-nav-settings">
        <div className="profile-setting-container action-toggle-container">
          <div className="component-toggle-container">
            <div className="actions-toggle-label">Prompt members for Daily Check-ins</div>
            <div className="daily-checkin-settings-container">
              {editOrganization.isLoading ? <PuffLoader color="#fff" size={38} /> : <Switch checked={currentOrganization?.dailyCheckin} onChange={() => toggleDailyCheckins(!currentOrganization?.dailyCheckin)}/> }
              {(currentOrganization?.dailyCheckin && !editOrganization.isLoading) && <div onClick={() => editOrganizationRoleDailyCheckinAccessModal()} className="button secondary icon daily-checkin-person-icon"><PersonAddIcon/></div>}
            </div>
          </div>
        </div>
      </div>
      <div>
        {currentOrganization?.dailyCheckin &&
          <>
            <div className="profile-settings-wrapper daily-checkin-form-container">
            <div className="profile-setting-container">
              <h2>Daily Check-in Days of the Week Selection</h2>
              {errorDayMessage && <div className="edit-error">{errorDayMessage}</div>}
              {saveDayMessage && <div className="save-message">{saveDayMessage}</div>}
              <div className="daily-checkin-day-select-container">
                {daysOfTheWeekDisplay}
              </div>
              {editDailyCheckins.isLoading ? <PuffLoader color="#fff" size={38} /> : <div className="button footer-button" data-testid="edit-save-button" onClick={() => updateDailyCheckinDays()}>Save</div> }
            </div>
          </div>
            <div className="profile-settings-wrapper daily-checkin-form-container">
              <div className="profile-setting-container">
                <h2>Daily Check-in Questions</h2>
                {errorMessage && <div className="edit-error">{errorMessage}</div>}
                {saveMessage && <div className="save-message">{saveMessage}</div>}
                <div className="profile-fields-container" data-testid="profile-settings-form">
                  {dailyCheckinFormFields}
                </div>
                {editDailyCheckins.isLoading ? <PuffLoader color="#fff" size={38} /> : <div className="button footer-button" data-testid="edit-save-button" onClick={() => submitDailyCheckins()}>Save</div> }
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default OrganizationSettingsActions
