import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { useAuthContext } from '../context/AuthContext'
import { Amplify } from 'aws-amplify'
import { config } from '../shared/config'
import Layout from '../pages/Layout'
import Login from '../pages/Login'
import Pathway from '../pages/Pathway'
import Profile from '../pages/Profile'
import Spaces from '../pages/Spaces'
import Space from '../pages/Space'
import ActionsPage from '../pages/ActionsPage'
import SpaceConnectionHistory from '../pages/SpaceConnectionHistory'
import ErrorPage from '../pages/ErrorPage'
import Connection from '../pages/Connection'
import Insights from '../pages/Insights'
import WorkspaceSettingsPeople from '../pages/OrganizationSettings/People'
import WorkspaceSettingsPerson from '../pages/OrganizationSettings/Person'
import LoadingScreen from '../components/LoadingScreen'
import CourseDetail from '../pages/CourseDetail'
import PublicLayout from '../pages/PublicLayout'
import OrganizationConnect from '../pages/OrganizationConnect'
import OrganizationNew from '../pages/OrganizationNew'
import OrganizationSettingsLayout from '../pages/OrganizationSettingsLayout'
import ProfileSettingsGeneral from '../pages/ProfileSettingsGeneral'
import ProfileSettingsSecurity from '../pages/ProfileSettingsSecurity'
import PersonCourseProgress from '../pages/PersonCourseProgress'
import ReportsWeeklyDetail from '../pages/OrganizationSettings/ReportsWeeklyDetail'
import Coaching from '../pages/Coaching'
import { getActiveOrganizations, getCurrentOrganization, isNewUserWithOrganization } from '../shared/utilities'
import { isAuthorized } from '../shared/permissions'
import { getOrganizationAdminPolicy } from '../shared/policies'
import OrganizationSettingsBilling from '../pages/OrganizationSettings/Billing'
import WorkspaceSettingsCourses from '../pages/OrganizationSettings/Courses'
import OrganizationSettingsIntegrations from '../pages/OrganizationSettings/SettingsIntegrations'
import OrganizationSettingsActions from '../pages/OrganizationSettings/SettingsActions'
import MarketPlace from '../pages/MarketPlace'
import Learn from '../pages/Learn'
import { useModal } from '../context/ModalContext'
import { useEffect, useState } from 'react'
import ActivateOrganization from '../modals/ActivateOrganization'
import InactiveOrganizationRoles from '../modals/InactiveOrganizationRoles'
import Goals from '../pages/Goals'
import CourseProgress from '../pages/CourseProgress'
import ReportsWeeklyOverview from '../pages/OrganizationSettings/ReportsWeeklyOverview'
import './index.css'
import Register from '../pages/Register'
import SSO from '../pages/SSO'
import { Feature } from '../shared/features'
import { isFeatureEnabled } from '../shared/posthog'
import Invite from '../pages/Invite'
import { OrganizationSettings } from '../pages/OrganizationSettings'
import WorkspaceSettingsSpaces from '../pages/OrganizationSettings/Spaces'
import { PeopleGallery } from '../pages/PeopleGallery'
import ClearStorage from '../pages/ClearStorage'

Amplify.configure(config.AWS)

function RedirectPersonDetails () {
  const { id } = useParams<{ id: string }>()
  return <Navigate to={`/organizations/settings/people/${id}`} replace />
}

function RedirectCourseDetails () {
  const { id, courseVersionId } = useParams<{ id: string; courseVersionId: string }>()
  return <Navigate to={`/organizations/settings/people/${id}/course/${courseVersionId}`} replace />
}

function App () {
  const {
    isLoading: isAuthLoading,
    firebaseToken,
    currentUser,
    isInitialLoading: isCurrentUserLoading,
    isSingingOut
  } = useAuthContext()
  const { makeModal } = useModal()

  const currentOrganization = getCurrentOrganization(currentUser)
  const activeOrganizationRoles = currentUser?.organizationRoles?.filter((role) => role.status !== 'INACTIVE')
  const [displayTrialBanner, setDisplayTrialBanner] = useState(true)

  useEffect(() => {
    if (currentUser && currentOrganization?.frozen) {
      makeModal({
        modal: <ActivateOrganization />,
        modalClose: false
      })
    } else if (currentUser && (!activeOrganizationRoles || activeOrganizationRoles.length < 1)) {
      makeModal({
        modal: <InactiveOrganizationRoles />,
        modalClose: false
      })
    }
  }, [currentOrganization, currentUser])

  const isAdminOrOwner =
    !!firebaseToken &&
    !!currentUser &&
    isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)
  const isNormalUser = !!firebaseToken && !!currentUser
  const isUserWithoutOrganizations =
    !!firebaseToken &&
    !!currentUser &&
    getActiveOrganizations(currentUser).length === 0 &&
    !isNewUserWithOrganization(currentUser)

  if (isAuthLoading || isCurrentUserLoading) {
    return <LoadingScreen />
  }

  if (isUserWithoutOrganizations) {
    return (
      <Routes>
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/" element={<PublicLayout />}>
          <Route path="/course/:uid" element={<CourseDetail />} />
          <Route path="/organizations/connect" element={<OrganizationConnect />} />
          <Route path="/organizations/new" element={<OrganizationNew />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/invite/:token" element={<Invite />} />
          <Route path="*" element={<Register />} />
        </Route>
      </Routes>
    )
  }

  if (isAdminOrOwner) {
    return (
      <Routes>
        <Route path="/" element={<Layout currentUser={currentUser} displayTrialBanner={displayTrialBanner} setDisplayTrialBanner={setDisplayTrialBanner}/>}>
          <Route path="/" element={<ActionsPage />} />
          <Route path="/sso" element={<Navigate to="/" replace />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/courses" element={<Pathway />} />
          <Route path="/spaces" element={<Spaces />} />
          <Route path="/courses/:id" element={<CourseProgress />} />
          <Route path="/connections/:uid/report" element={<SpaceConnectionHistory />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/settings" element={<ProfileSettingsGeneral />} />
          <Route path="/profile/settings/security" element={<ProfileSettingsSecurity />} />
          {isFeatureEnabled(Feature.Insights) && <Route path="/insights" element={<Insights />} />}
          <Route path="/people/:id" element={<RedirectPersonDetails />} />
          <Route path="/people/:id/course/:courseVersionId" element={<RedirectCourseDetails />} />
          <Route path="/people" element={<PeopleGallery />} />
          {isFeatureEnabled(Feature.Learn) && <Route path="/learn" element={<Learn />} />}
          {isFeatureEnabled(Feature.Coaching) && <Route path="/coaching" element={<Coaching />} />}
          <Route path="*" element={<ErrorPage message={'404, this page does not exist'} />} />
        </Route>
        <Route path="/spaces/:id" element={<Space />} />
        <Route path="/connections/:uid" element={<Connection />} />
        <Route path="/" element={<OrganizationSettingsLayout currentUser={currentUser} displayTrialBanner={displayTrialBanner} setDisplayTrialBanner={setDisplayTrialBanner} />}>
          <Route path="/organizations/settings" element={<OrganizationSettings />} />
          <Route path="/organizations/settings/billing" element={<OrganizationSettingsBilling />} />
          <Route path="/organizations/settings/goals" element={<Goals />} />
          <Route path="/organizations/settings/reports" element={<ReportsWeeklyOverview />} />
          <Route path="/organizations/settings/integrations" element={<OrganizationSettingsIntegrations />} />
          <Route path="/organizations/settings/actions" element={<OrganizationSettingsActions />} />
          <Route path="/organizations/settings/spaces" element={<WorkspaceSettingsSpaces />} />
          <Route path="/organizations/settings/spaces/:id" element={<Space inOrganizationSettings />} />
          <Route path="/organizations/settings/courses" element={<WorkspaceSettingsCourses />} />
          <Route path="/organizations/settings/courses/marketplace" element={<MarketPlace />} />
          <Route path="/organizations/settings/people" element={<WorkspaceSettingsPeople />} />
          <Route path="/organizations/settings/people/:id" element={<WorkspaceSettingsPerson />} />
          <Route
            path="/organizations/settings/people/:id/course/:courseVersionId"
            element={<PersonCourseProgress />}
          />
          <Route path="/organizations/settings/reports/weekly" element={<ReportsWeeklyDetail />} />
        </Route>
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/" element={<PublicLayout />}>
          <Route path="/invite/:inviteCode" element={<Invite />} />
          <Route path="/course/:uid" element={<CourseDetail />} />
          <Route path="/organizations/connect" element={<OrganizationConnect />} />
          <Route path="/organizations/new" element={<OrganizationNew />} />
        </Route>
      </Routes>
    )
  }

  if (isNormalUser) {
    return (
      <Routes>
        <Route path="/" element={<Layout currentUser={currentUser} displayTrialBanner={displayTrialBanner} setDisplayTrialBanner={setDisplayTrialBanner}/>}>
          <Route path="/" element={<ActionsPage />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/sso" element={<Navigate to="/" replace />} />
          <Route path="/courses" element={<Pathway />} />
          <Route path="/spaces" element={<Spaces />} />
          <Route path="/courses/:id" element={<CourseProgress />} />
          <Route path="/connections/:uid/report" element={<SpaceConnectionHistory />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/settings" element={<ProfileSettingsGeneral />} />
          <Route path="/profile/settings/security" element={<ProfileSettingsSecurity />} />
          <Route path="/people/:id" element={<WorkspaceSettingsPerson />} />
          <Route path="/people" element={<PeopleGallery />} />
          {isFeatureEnabled(Feature.Learn) && <Route path="/learn" element={<Learn />} />}
          {isFeatureEnabled(Feature.Coaching) && <Route path="/coaching" element={<Coaching />} />}
          <Route path="*" element={<ErrorPage message={'404, this page does not exist'} />} />
        </Route>
        <Route path="/spaces/:id" element={<Space />} />
        <Route path="/connections/:uid" element={<Connection />} />
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/" element={<PublicLayout />}>
          <Route path="/invite/:inviteCode" element={<Invite />} />
          <Route path="/course/:uid" element={<CourseDetail />} />
          <Route path="/organizations/connect" element={<OrganizationConnect />} />
          <Route path="/organizations/new" element={<OrganizationNew />} />
        </Route>
      </Routes>
    )
  }

  if (!isSingingOut) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sso" element={<SSO />} />
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/" element={<PublicLayout />}>
          <Route path="/invite/:inviteCode" element={<Invite />} />
          <Route path="/course/:uid" element={<CourseDetail />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
      </Routes>
    )
  }

  return <></>
}

export default App
