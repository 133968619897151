import { useEffect, useState } from 'react'
import { useModal } from '../../context/ModalContext'
import { useQueryClient, useUpdateOrganizationCourseAccessMutation, useSpacesQuery } from '../../shared/queryHooks'
import { sortOrgCourses } from '../../shared/utilities'
import { Checkbox } from '@mui/material'
import { getOrganizationCourses } from '../../shared/api'
import { PuffLoader } from 'react-spinners'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'
import { OrganizationCourse, OrganizationRole } from '../../types'
import LoadingScreen from '../../components/LoadingScreen'

interface ManageUserCoursesProps {
  person: OrganizationRole
}

function ManageUserCourses ({ person }: ManageUserCoursesProps) {
  const { setTitle, clearModal } = useModal()
  const queryClient = useQueryClient()
  const updatePersonCourseAccess = useUpdateOrganizationCourseAccessMutation()
  const { currentUser } = useAuthContext()
  const { isLoading, data: spaces } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, {
    enabled: !!currentUser
  })
  const personSpaces = spaces?.filter((space) =>
    space?.spaceRoles?.some((role) => role?.organizationRoleId === person?.id)
  )

  const [orgCourses, setOrgCourses] = useState<OrganizationCourse[]>([])

  function useInASpaceCourse (): boolean {
    return orgCourses.some(
      (orgCourse) => personSpaces?.some((space) => space.courseVersionId === orgCourse.courseVersionId) ?? false
    )
  }

  useEffect(() => {
    const fetchOrganizationRoles = async () => {
      setTitle(`${person?.contact?.firstName}'s Enrolled Courses`)
      const orgCoursesData = await getCurrentOrganizationCourses()
      setOrgCourses(orgCoursesData)
    }
    void fetchOrganizationRoles()
  }, [])

  async function getCurrentOrganizationCourses () {
    return await queryClient.fetchQuery(['courses'], () => getOrganizationCourses())
  }

  function handleChange ({ courseId, access }) {
    const orgCourse = orgCourses?.find((orgCourse) => orgCourse?.id === courseId)
    if (!orgCourse) return
    const orgRoleAccess = JSON.parse(orgCourse.organizationRoleAccess ?? '{}')
    const personAccess = orgRoleAccess?.find((role) => role?.organizationRoleId === person?.id)
    if (personAccess) {
      personAccess.access = access
    } else {
      orgRoleAccess.push({ organizationRoleId: person?.id, access })
    }
    orgCourse.organizationRoleAccess = JSON.stringify(orgRoleAccess)
    setOrgCourses(orgCourses.map((opr) => ({ ...opr, orgCourse })))
  }

  async function handleSave () {
    await updatePersonCourseAccess.mutateAsync({
      organizationId: currentUser?.currentOrganizationId,
      body: orgCourses
    })
    clearModal()
  }

  const personCourseAccessDisplay = sortOrgCourses(orgCourses)?.map((orgCourse) => {
    const personCourseAccess =
      orgCourse &&
      JSON.parse(orgCourse?.organizationRoleAccess)?.filter((role) => role?.organizationRoleId === person?.id)[0]
        ?.access
    const personInSpaceWithCourse =
      personSpaces?.some((space) => space.courseVersionId === orgCourse.courseVersionId) ?? false

    return (
      <div className="course-access-container" key={orgCourse?.id}>
        <Checkbox
          checked={personCourseAccess}
          disabled={personInSpaceWithCourse}
          onChange={() => handleChange({ courseId: orgCourse?.id, access: !personCourseAccess })}
        />
        <div>
          {orgCourse?.courseVersion?.course?.name} v{orgCourse?.courseVersion?.version}.0.0
          {personInSpaceWithCourse ? '*' : ''}
        </div>
      </div>
    )
  })

  return (
    <div>
      {isLoading
        ? (
        <LoadingScreen />
          )
        : (
        <div>
          <div>{personCourseAccessDisplay}</div>
          {useInASpaceCourse() && (
            <div className="person-in-space-with-course">
              *This person is in a space that uses this course and cannot be removed. You must first remove them form
              the space.
            </div>
          )}
          <div className="modal-footer">
            <div className="button secondary" onClick={clearModal}>
              Cancel
            </div>
            <div className="button" onClick={() => handleSave()}>
              {updatePersonCourseAccess?.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Save'}
            </div>
          </div>
        </div>
          )}
    </div>
  )
}

export default ManageUserCourses
