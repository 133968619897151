import { logWarn } from './logger'

const stage = process.env.REACT_APP_STAGE

const addFirebaseAuthToken = () => {
  try {
    const userAccessToken = localStorage.getItem('firebaseToken')
    const inviteCode = localStorage.getItem('inviteCode') ?? ''
    if (userAccessToken) {
      const organizationId = localStorage.getItem('lastOrganizationId')
      return { authorization: userAccessToken, inviteCode, organizationId }
    }
  } catch (error) {
    logWarn(error)
    return {}
  }
}

const CONFIG_PROD = {
  AWS: {
    API: {
      endpoints: [
        {
          name: 'ENGINEER_KIT_API',
          endpoint: 'https://api.connectbetter.io',
          custom_header: addFirebaseAuthToken
        },
        {
          name: 'FREECOM_CHECK_API',
          endpoint: 'https://wlvjr3etqc.execute-api.us-east-1.amazonaws.com'
        }
      ]
    }
  },
  FIREBASE: {
    Auth: {
      apiKey: 'AIzaSyChC1hdV4x22Y72tp0O30MeBWoco3Ky12Q',
      authDomain: 'connectbetter.io',
      projectId: 'avoda-prod-8710c',
      endpoint: 'https://app.connectbetter.io'
    }
  },
  SENTRY: {
    DSN: 'https://e9f042fd28bf4a7cb1d2dcbb09e63489@o1254450.ingest.sentry.io/6422340'
  },
  GOOGLE: {
    MAPS_ENABLED: false,
    API_KEY: 'AIzaSyCx8PnlrnixdMSn7qja_SjE2GhpltbqdDw'
  },
  CRISP: {
    WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40'
  },
  STRIPE: {
    API_KEY: 'pk_live_51LuLuGDGl4W5zqduyGL3qRtuNdJh4Z3CCzj0FMeJRGBmQXLHYt5abC9qvY9QvsccVBc40PeQfxF3Nod9b6qP58oT00rdcIJyqy'
  },
  CONNECTBETTER_API: {
    WEBSOCKET_HOST: 'wss://api.connectbetter.io',
    CDN_HOST: 'https://cdn.connectbetter.io'
  },
  POSTHOG: {
    API_KEY: 'phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH'
  },
  STUDIO: {
    ENDPOINT: 'https://studio.connectbetter.io'
  },
  CONNECTBETTER: {
    ENDPOINT: 'https://app.connectbetter.io'
  },
  IDENTITY: {
    AVODA_IDENTITY_URL: 'https://identity.avoda.co'
  }
}

const CONFIG_STAGING = {
  AWS: {
    API: {
      endpoints: [
        {
          name: 'ENGINEER_KIT_API',
          endpoint: 'https://staging-api.connectbetter.io',
          custom_header: addFirebaseAuthToken
        },
        {
          name: 'FREECOM_CHECK_API',
          endpoint: 'https://wlvjr3etqc.execute-api.us-east-1.amazonaws.com/'
        }
      ]
    }
  },
  FIREBASE: {
    Auth: {
      apiKey: 'AIzaSyBH9xW6plWFiHrN---oaN1D8E41l48tH3c',
      authDomain: 'connectbetter.io',
      projectId: 'avoda-dev-ae07c',
      endpoint: 'https://staging-app.connectbetter.io'
    }
  },
  SENTRY: {
    DSN: ''
  },
  GOOGLE: {
    MAPS_ENABLED: false,
    API_KEY: 'AIzaSyCx8PnlrnixdMSn7qja_SjE2GhpltbqdDw'
  },
  CRISP: {
    WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40'
  },
  STRIPE: {
    API_KEY: 'pk_test_51LuLuGDGl4W5zqduK8MGiWERthFT51oFudeb8u42QyJchWjuqWEi7Vd6wWk59dUdCXQ4BmTxaGYSdau2ygHJoPui00HhaPYipf'
  },
  CONNECTBETTER_API: {
    WEBSOCKET_HOST: 'wss://staging-api.connectbetter.io',
    CDN_HOST: 'https://staging-cdn.connectbetter.io'
  },
  POSTHOG: {
    API_KEY: ''
  },
  STUDIO: {
    ENDPOINT: 'https://staging-studio.connectbetter.io'
  },
  CONNECTBETTER: {
    ENDPOINT: 'https://staging-app.connectbetter.io'
  },
  IDENTITY: {
    AVODA_IDENTITY_URL: 'https://staging-identity.avoda.co'
  }
}

const CONFIG_DEV = {
  AWS: {
    API: {
      endpoints: [
        {
          name: 'ENGINEER_KIT_API',
          endpoint: 'http://localhost:4000',
          custom_header: addFirebaseAuthToken
        },
        {
          name: 'FREECOM_CHECK_API',
          endpoint: 'https://wlvjr3etqc.execute-api.us-east-1.amazonaws.com/'
        }
      ]
    }
  },
  FIREBASE: {
    Auth: {
      apiKey: 'AIzaSyBH9xW6plWFiHrN---oaN1D8E41l48tH3c' || 'initialKeyState',
      authDomain: 'localhost:3000',
      projectId: 'avoda-dev-ae07c',
      endpoint: 'http://localhost:3000'
    }
  },
  SENTRY: {
    DSN: ''
  },
  GOOGLE: {
    MAPS_ENABLED: true,
    API_KEY: 'AIzaSyCx8PnlrnixdMSn7qja_SjE2GhpltbqdDw'
  },
  CRISP: {
    WEBSITE_ID: ''
  },
  STRIPE: {
    API_KEY: 'pk_test_51LuLuGDGl4W5zqduK8MGiWERthFT51oFudeb8u42QyJchWjuqWEi7Vd6wWk59dUdCXQ4BmTxaGYSdau2ygHJoPui00HhaPYipf'
  },
  CONNECTBETTER_API: {
    WEBSOCKET_HOST: 'ws://localhost:4000',
    CDN_HOST: 'https://staging-cdn.connectbetter.io'
  },
  POSTHOG: {
    API_KEY: ''
  },
  STUDIO: {
    ENDPOINT: 'http://localhost:3001'
  },
  CONNECTBETTER: {
    ENDPOINT: 'http://localhost:3000'
  },
  IDENTITY: {
    AVODA_IDENTITY_URL: 'https://localhost:3334'
  }
}

const getConfigForEnvironment = () => {
  if (stage === 'production') {
    return CONFIG_PROD
  } else if (stage === 'staging') {
    return CONFIG_STAGING
  } else {
    return CONFIG_DEV
  }
}

const config = getConfigForEnvironment()

export {
  config
}
