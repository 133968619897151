
import { PuffLoader } from 'react-spinners'
import { useModal } from '../../context/ModalContext'
import { useExercisesActionsApprovalQuery, useUpdateOrganizationCourseMutation } from '../../shared/queryHooks'
import LoadingScreen from '../../components/LoadingScreen'
import { logError } from '../../shared/logger'
import { useState } from 'react'
import { Checkbox } from '@mui/material'
import './index.css'

interface OrganizationCourseReviewProps {
  id: number
  courseVersionId: number
  enabled: boolean
  access: string
}

function OrganizationCourseReview ({ id, courseVersionId, enabled, access }: OrganizationCourseReviewProps) {
  const { isLoading, isError, data: exercises, error } = useExercisesActionsApprovalQuery(courseVersionId)
  const { clearModal } = useModal()
  const updateOrganizationCourse = useUpdateOrganizationCourseMutation()
  const [approveActions, setApproveActions] = useState(false)

  const handleApproval = () => {
    updateOrganizationCourse.mutate({ id, access: JSON.parse(access), enabled, requiresReview: false, actionsApproved: approveActions })
    clearModal()
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
  }

  return (
    <>
      <div>
        <div className="description">The publisher of this course as set the following exercises as schedule actions. These actions must be review before this course version can be available inside of your workspace. If you approve these actions, they will automatically be created for all users that are subscribed to this course. If you do not approvae these actions they will not be created for your subscribed users. Warning, this choice can only be made once. Saving with option will determing the onboarding process for this course version.</div>
        {exercises?.map(exercise => {
          const dueBy = new Date()
          dueBy.setDate(dueBy.getDate() + (exercise?.publisherDueBy ?? 0))
          const formattedDate = dueBy.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
          })

          return (
            <div key={exercise?.id} className="review-exercise-list-item">
              <div>
                <div className="exercise-title">{exercise?.title} - {exercise?.section?.title}</div>
                <div className="exercise-description" style={{ opacity: '0.6' }}>Due by {formattedDate}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div>
        <Checkbox defaultChecked={approveActions} onChange={() => setApproveActions(!approveActions)} value={approveActions} />
        <span>Approve publisher actions</span>
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button" onClick={() => handleApproval()}>{updateOrganizationCourse?.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Save'}</div>
      </div>
    </>
  )
}

export default OrganizationCourseReview
