import { useAuthContext } from '../../context/AuthContext'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import './index.css'

function ProfileSettingsSecurity () {
  const { signOutUser } = useAuthContext()
  return (
    <>
      <SecondaryNavBar title="Security"/>
      <div className="profile-security-wrapper" data-testid="profile-security-wrapper">
        <div className="button danger" data-testid="profile-security-signout-button" onClick={signOutUser}>Sign Out</div>
      </div>
    </>
  )
}

export default ProfileSettingsSecurity
