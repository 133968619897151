import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useState } from 'react'
import ActionSettingsMenuItems from '../ActionSettingsMenuItems'
import { useOutsideAlerter } from '../../shared/utilities'
import './index.css'

interface ActionSettingsMenuProps {
  actionId: number
  currentUser: any
  existingAction: any
  rescheduleConnection?: any
}

function ActionSettingsMenu ({ actionId, currentUser, existingAction, rescheduleConnection } : ActionSettingsMenuProps) {
  const [actionMenu, setActionMenu] = useState<number | null>(null)

  const actionSettingsMenuItems = () => {
    return (
      <ActionSettingsMenuItems
        actionId={actionId}
        existingAction={existingAction}
        currentUser={currentUser}
        useOutsideAlerter={useOutsideAlerter}
        setActionMenu={setActionMenu}
        rescheduleConnection={rescheduleConnection}
      />
    )
  }

  return (
    <div>
      <div className="action-button-container">
        <div className="button transparent action-button" onClick={actionMenu === actionId ? () => setActionMenu(null) : () => setActionMenu(actionId)}>
          <MoreHorizIcon />
        </div>
      </div>
      <div className="settings-items-wrapper">
        { actionMenu === actionId &&
        actionSettingsMenuItems() }
      </div>
    </div>
  )
}

export default ActionSettingsMenu
