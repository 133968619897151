import { createContext, useContext, useState } from 'react'
import * as Sentry from '@sentry/react'
import { logError, logInfo, logWarn } from '../shared/logger'
import { config } from '../shared/config'
import { Crisp } from 'crisp-sdk-web'
import { useEffectOnce } from '../shared/hooks'
import { useUserQuery } from '../shared/queryHooks'
import { User } from '../types'
import jwtDecode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'
import { generateCSRF } from '../shared/utilities'
import posthog from 'posthog-js'

interface AuthContextProps {
  signOutUser: () => Promise<void>;
  isLoading: boolean;
  firebaseToken: string | null;
  currentUser: User | null;
  isInitialLoading: boolean;
  isSingingOut: boolean;
}

export const AuthContext = createContext<AuthContextProps>({
  signOutUser: () => Promise.resolve(),
  isLoading: true,
  firebaseToken: null,
  currentUser: null,
  isInitialLoading: true,
  isSingingOut: false
})

const AuthProvider = (props: any): JSX.Element => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('t') ?? ''
  const csrf = searchParams.get('csrf') ?? ''
  const inviteCode = searchParams.get('inviteCode') ?? ''
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [firebaseToken, setFirebaseToken] = useState<string | null>(null)
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [isSingingOut, setIsSigningOut] = useState<boolean>(false)

  function setOrganizationId (organizationId: number) {
    localStorage.setItem('lastOrganizationId', organizationId.toString())
  }

  function isTokenExpired (): boolean {
    if (!firebaseToken) return true
    const decodedToken: any = jwtDecode(firebaseToken)
    const expirationDate = new Date(decodedToken.exp * 1000)
    if (expirationDate < new Date()) {
      logInfo('Token expired')
      setFirebaseToken(null)
      localStorage.removeItem('firebaseToken')
      return true
    }
    return false
  }

  if (inviteCode) localStorage.setItem('inviteCode', inviteCode)
  const { isInitialLoading, isError, data, error } = useUserQuery({
    enabled: !!firebaseToken && !isTokenExpired()
  })
  if (isError) {
    logError(error)
  }

  if (data && currentUser) {
    localStorage.removeItem('inviteCode')
  }

  if (data && currentUser && data !== currentUser) {
    setCurrentUser(data)
  }

  if (data && !currentUser) {
    try {
      localStorage.removeItem('inviteCode')
      setOrganizationId(data?.currentOrganizationId ?? 0)

      const fullName = `${data.firstName ?? ''} ${data.lastName ?? ''}`
      Crisp.user.setEmail(data.email)
      Crisp.user.setNickname(fullName)
      Sentry.setUser({
        authUid: data?.authUid,
        email: data?.email,
        name: fullName
      })
      posthog.identify(data.authUid!, { email: data?.email, name: fullName })

      setCurrentUser(data)
    } catch (error) {
      logError(error)
    }
  }

  useEffectOnce(() => {
    signInUserWithToken(token, csrf)
    setIsLoading(false)
  })

  function signInUserWithToken (token, csrf) {
    const localCsrf = localStorage.getItem('csrf')
    if (token && csrf === localCsrf) {
      localStorage.setItem('firebaseToken', token)
      setFirebaseToken(token)
      localStorage.removeItem('csrf')
    } else if (localStorage.getItem('firebaseToken')) {
      setFirebaseToken(localStorage.getItem('firebaseToken'))
    } else {
      logWarn('No token found')
    }
  }

  function signOutUser () {
    const inviteCode = localStorage.getItem('inviteCode')
    setIsSigningOut(true)
    logInfo('Signing out user')
    localStorage.removeItem('firebaseToken')

    posthog.reset()
    Sentry.setUser(null)

    setFirebaseToken(null)
    setCurrentUser(null)
    const csrf = generateCSRF()
    const url =
      `${config.IDENTITY.AVODA_IDENTITY_URL}/signout/?sourceUrl=${config.CONNECTBETTER.ENDPOINT}/sso&csrf=${csrf}` +
      (inviteCode ? `&inviteCode=${inviteCode}` : '')
    window.location.replace(url)
  }

  return (
    <AuthContext.Provider
      value={{
        signOutUser,
        isLoading,
        firebaseToken,
        currentUser,
        isInitialLoading,
        isSingingOut
      }}
      {...props}
    />
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
