import { useEffectOnce } from '../../shared/hooks'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'

function Invite () {
  const { signOutUser } = useAuthContext()
  const { inviteCode } = useParams<{inviteCode: string}>()
  useEffectOnce(() => {
    const handleLogout = async () => {
      if (inviteCode) localStorage.setItem('inviteCode', inviteCode)
      await signOutUser()
    }
    void handleLogout()
  })

  return <></>
}

export default Invite
