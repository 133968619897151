import { useWeeklyReportQuery } from '../../../shared/queryHooks'
import { formatDateString, getCurrentOrganization } from '../../../shared/utilities'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import LoadingScreen from '../../../components/LoadingScreen'
import WeeklyAchievements from '../../../components/WeeklyAchievements'
import WeeklyHealth from '../../../components/WeeklyHealth'
import ErrorPage from '../../ErrorPage'
import { useAuthContext } from '../../../context/AuthContext'
import WeeklyFreedomChecks from '../../../components/WeeklyFreedomChecks'
import './index.css'

function ReportsWeeklyDetail () {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const startWeekLocal = parseInt(decodeURIComponent(searchParams.get('start') ?? '0'))
  const endWeekLocal = parseInt(decodeURIComponent(searchParams.get('end') ?? '0'))

  const { isLoading, isError, data: reports, error, isFetching } = useWeeklyReportQuery(currentOrganization?.id ?? 0, startWeekLocal, endWeekLocal)
  const timePeriod = `${formatDateString(startWeekLocal)} - ${formatDateString(endWeekLocal)}`

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar backTo={'/organizations/settings/reports'} title="Reports" />
        <LoadingScreen />
      </>
    )
  }

  if (isError) {
    const e: any = error
    const message = e?.request?.status === 404 ? '404, this page does not exist' : 'Something went wrong'
    return (
      <>
        <SecondaryNavBar backTo={'/organizations/settings/reports'} title="Reports" />
        <ErrorPage message={message} action={() => navigate('/')} actionTitle="Return home?" />
      </>)
  }

  const sortSpaces = (a, b) => a.name.localeCompare(b.name)

  const spacesWithConnects = reports?.weeklySpaceSummary?.filter(space => space?.uids?.length > 0).sort(sortSpaces)
  const spacesWithoutConnects = reports?.weeklySpaceSummary?.filter(space => space?.uids?.length === 0).sort(sortSpaces)
  const spaces = [...spacesWithConnects, ...spacesWithoutConnects]

  return (
    <>
      <SecondaryNavBar backTo={'/organizations/settings/reports'} title="Reports" />
      <div className="weekly-report-container report-nav">
        <WeeklyHealth data={spaces} timePeriod={timePeriod} isFetching={isFetching} />
        <WeeklyAchievements data={reports?.weeklyAchievements} timePeriod={timePeriod}/>
        {reports?.hasFreedomChecks && <WeeklyFreedomChecks startTime={startWeekLocal} endTime={endWeekLocal} organizationId={currentUser?.currentOrganizationId} timePeriod={timePeriod}/>}
      </div>
    </>
  )
}

export default ReportsWeeklyDetail
