import { add, startOfWeek, endOfWeek, isBefore } from 'date-fns'
import { useOrganizationReportsQuery } from '../../../shared/queryHooks'
import { Link } from 'react-router-dom'
import { formatDateString } from '../../../shared/utilities'
import { useAuthContext } from '../../../context/AuthContext'
import { logError } from '../../../shared/logger'
import insightsIllustration from '../../../assets/insights.svg'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import LoadingScreen from '../../../components/LoadingScreen'
import EmptyHero from '../../../components/EmptyHero'
import './index.css'

function ReportsWeeklyOverview () {
  const { currentUser } = useAuthContext()

  const { isLoading, isError, data: reports, error } = useOrganizationReportsQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser })

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar title="Reports" />
        <LoadingScreen />
      </>
    )
  }

  if (isError) {
    logError(error)
    return (
      <SecondaryNavBar title="Reports" />
    )
  }

  const getWeekRanges = () => {
    const startDateTimeUTC = reports[0]?.startTime
    const startWeek = startOfWeek(new Date(startDateTimeUTC))
    const weekList: { startWeek: Date, endWeek: Date }[] = []

    const addAndIncrementWeek = (startWeek) => {
      const endWeek = endOfWeek(new Date(startWeek))
      weekList.push({ startWeek, endWeek })
      if ((isBefore(add(startWeek, { weeks: 1 }), new Date()))) {
        addAndIncrementWeek(add(startWeek, { weeks: 1 }))
      }
    }
    addAndIncrementWeek(startWeek)
    weekList.sort((a, b) => b.startWeek.getTime() - a.startWeek.getTime())
    return weekList
  }

  const weekRangeLinks = (
    getWeekRanges().map((item, index) => {
      const params = new URLSearchParams({ start: item.startWeek.getTime().toString(), end: item.endWeek.getTime().toString() })
      const formattedWeekString = `${formatDateString(item.startWeek)}-${formatDateString(item.endWeek)}`
      return (
        <Link
          data-testid={formattedWeekString}
          key={index}
          to={`/organizations/settings/reports/weekly?${params?.toString()}`}>
          {formattedWeekString}
        </Link>
      )
    })
  )

  return (
    <>
      <SecondaryNavBar title="Reports" />
      {reports.length > 0 && <div className='reports-page-container report-nav'>
        <div className='weekly-history-container'>
          <h2>Weekly Reports</h2>
          <div className="weekly-history-item">
            {weekRangeLinks}
          </div>
        </div>
      </div>}
      {!reports || reports.length === 0
        ? (
          <EmptyHero
            image={insightsIllustration}
            title="Weekly Reports"
            data-testid="reports-empty-hero"
            description="You don't have any reports yet. Once you've had some activity in your spaces, analytics will appear here." />
          )
        : <></>}
    </>
  )
}

export default ReportsWeeklyOverview
