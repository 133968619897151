import { config } from './config'
import { loadStripe } from '@stripe/stripe-js'

async function setupStripe () {
  try {
    return loadStripe(config.STRIPE.API_KEY)
  } catch (error) {
    console.error(error)
  }
}

const stripeElementsOptions = {
  fonts: [
    { cssSrc: 'https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap' }
  ]
}

export {
  setupStripe,
  stripeElementsOptions
}
