import { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import CourseHighlight from '../../components/CourseHighlight'
import { useAuthContext } from '../../context/AuthContext'
import { canCreateOrganization, getOrganizationsAsAdmin, getCourseUidFromSearchParams, useSwitchOrganization } from '../../shared/utilities'
import { useCreateOrganizationMutation, usePlansQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import LoadingScreen from '../../components/LoadingScreen'
import { Entity } from '../../shared/enums'

function OrganizationNew () {
  const [searchParams] = useSearchParams()
  const { currentUser } = useAuthContext()
  const courseUid = getCourseUidFromSearchParams(searchParams)
  const createOrganization = useCreateOrganizationMutation()
  const { switchOrganization } = useSwitchOrganization()
  const navigate = useNavigate()
  const [organizationName, setOrganizationName] = useState('')
  const [organizationDomain, setOrganizationDomain] = useState('')
  const organizations = getOrganizationsAsAdmin(currentUser)
  const { isLoading, data: plans } = usePlansQuery()

  async function onCreateOrganization (name, courseUid, domain) {
    if (!canCreateOrganization()) return
    if (name) {
      try {
        const organization = await createOrganization.mutateAsync({ name, courseUid, domain })
        switchOrganization(organization.id)
        navigate('/')
      } catch (error) {
        logError(error)
      }
    }
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <>
      {courseUid && <CourseHighlight courseUid={courseUid} />}
      <div className="login-container" style={{ marginTop: '1rem' }}>
        <div className="title">Let&apos;s create a {Entity.Workspace}</div>
        <div className="subtitle">Free {plans?.trialDays}-day trial, no credit card required</div>
        {createOrganization.error ? <div className="login-error">Error creating {Entity.Workspace}, please try again</div> : <></>}
        <input
          autoFocus
          type="text"
          data-testid="organization-name-input"
          placeholder={`${Entity.Workspace} name`}
          onChange={(event) => setOrganizationName(event.target.value)}
          value={organizationName} />
          <input
          autoFocus
          type="text"
          data-testid="organization-domain-input"
          placeholder={`${Entity.Workspace} domain`}
          onChange={(event) => setOrganizationDomain(event.target.value)}
          value={organizationDomain} />
        <div className="button" data-testid="login-button" onClick={() => onCreateOrganization(organizationName, courseUid, organizationDomain)}>
          {createOrganization.isLoading ? <PuffLoader color='#fff' size={21} /> : 'Create and continue'}
        </div>
        {organizations?.length > 0 && courseUid && <Link to={`/organizations/connect?course=${courseUid}`} className="subtitle" style={{ marginTop: '1rem', marginBottom: 0 }}>Or choose an existing {Entity.Workspace}</Link>}
      </div>
      {currentUser?.organizationRoles && currentUser.organizationRoles.length > 0 && <div className="cancel-and-return" onClick={() => navigate('/')}>
        Cancel and return home
      </div>}
    </>
  )
}

export default OrganizationNew
