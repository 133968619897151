import { Outlet } from 'react-router-dom'
import NavBar from '../../components/NavBar'
import TrialEndingBanner from '../../components/TrialEndingBanner'
import useLayoutDetector from '../../shared/useLayoutDetector'

function Layout ({ currentUser, displayTrialBanner, setDisplayTrialBanner }) {
  useLayoutDetector()

  return (
    <div className="app">
      <TrialEndingBanner currentUser={currentUser} displayTrialBanner={displayTrialBanner} setDisplayTrialBanner={setDisplayTrialBanner}/>
      <NavBar />
      <div className="app-content">
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
