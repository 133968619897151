import { Alert, AlertTitle } from '@mui/material'
import { useAuthContext } from '../../context/AuthContext'
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded'
import Button from '../../components/Button'

import './index.css'
import { Entity } from '../../shared/enums'

function InactivveOrganizationRoles () {
  const { signOutUser } = useAuthContext()

  return (
    <div className="inactive-organizationRole-modal">
      <Alert icon={<AcUnitRoundedIcon />} severity="info">
      <AlertTitle>No Active {Entity.Workspace} Roles</AlertTitle>
        You currently have no active {Entity.Workspace} roles. Please contact your admin to reinstate you to a {Entity.Workspace} to access your account.
      </Alert>
      <div className="modal-footer">
        <Button className="button secondary" onClick={signOutUser}>Sign out</Button>
      </div>
    </div>
  )
}

export default InactivveOrganizationRoles
