import { useModal } from '../../context/ModalContext'
import EditContactNotes from '../../modals/EditContactNotes'
import './index.css'

function PersonNotes ({ person }) {
  const { makeModal } = useModal()

  const notes = person?.contact?.notes ?? ''

  return (
    <div>
      <div className="person-notes-container">
        {notes.split('\n').map((line, i) => (
          <p key={i}>{line}</p>
        ))}
      </div>
      <div className="person-notes-footer">
        <div
          data-testid="organization-user-notes-button"
          className="button"
          onClick={() =>
            makeModal({
              modal: (
                <EditContactNotes person={person} isEditingContact={true} />
              )
            })
          }
        >
          Edit notes
        </div>
      </div>
    </div>
  )
}

export default PersonNotes
