import { useState, useRef } from 'react'
import { generateAvatarUploadUrlForUser } from '../../shared/api'
import { useAuthContext } from '../../context/AuthContext'
import { useUserMutation } from '../../shared/queryHooks'
import { useQueryClient } from '@tanstack/react-query'
import { logError } from '../../shared/logger'
import { postAvatarUrl } from '../../shared/s3'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import { ImageListType } from 'react-images-uploading'
import { parsedErrorMessage } from '../../shared/errors'
import ProfileSettings from '../../components/ProfileSettings'

function ProfileSettingsForm () {
  const { currentUser, signOutUser } = useAuthContext()
  const editor = useRef<any>(null)
  const queryClient = useQueryClient()
  const editUser = useUserMutation()

  const [user, setUser] = useState({
    firstName: currentUser?.firstName ?? '',
    lastName: currentUser?.lastName ?? '',
    currentOrganizationId: currentUser?.currentOrganizationId,
    shareData: currentUser?.shareData ?? false,
    email: currentUser?.email ?? ''
  })
  const [avatar, setAvatar] = useState<ImageListType>(
    currentUser?.avatar ? [{ data_url: currentUser?.avatar }] : []
  )

  const [hasChangedAvatar, setHasChangedAvatar] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [saveMessage, setSaveMessage] = useState('')

  const onChange = ({ attribute, value }) => {
    setUser(prev => ({ ...prev, [attribute]: value }))
    setSaveMessage('')
  }

  function isEmptyNameField ({ value, attribute }) {
    setSaveMessage('')
    setUser(prev => ({ ...prev, [attribute]: value }))

    if (value.trim() === '') {
      setErrorMessage(`Please enter a ${attribute}`)
    } else {
      setErrorMessage('')
    }
  }

  function hasRequiredAttributes () {
    if (user.firstName !== '' && user.lastName !== '') {
      return true
    }
    return false
  }

  async function submitForm () {
    if (!hasRequiredAttributes()) {
      setErrorMessage('Please enter all required attributes')
      return
    }

    if (avatar.length > 0) {
      if (editor.current?.props?.image) {
        const avatarUploadUrl = await queryClient.fetchQuery(['avatar'], () =>
          generateAvatarUploadUrlForUser()
        )
        postAvatarUrl({ avatarUploadUrl, editor })
      }
    }
    try {
      await editUser.mutateAsync({
        ...user,
        avatar: avatar.length > 0
      })
      setHasChangedAvatar(false)
      setSaveMessage('Your changes have been saved')
    } catch (error) {
      logError(error)
      setErrorMessage(parsedErrorMessage(error))
    }
  }

  return (
    <>
      <SecondaryNavBar title="Profile" />
      <div className="profile-settings-wrapper">
        <div className="profile-setting-container">
          {errorMessage && <div className="edit-error">{errorMessage}</div>}
          {saveMessage && <div className="save-message">{saveMessage}</div>}
          <div
            className="profile-fields-container"
            data-testid="profile-settings-form"
          >
            <ProfileSettings
              user={user}
              avatar={avatar}
              onChange={onChange}
              setAvatar={setAvatar}
              editor={editor}
              hasChangedAvatar={hasChangedAvatar}
              setHasChangedAvatar={setHasChangedAvatar}
              setSaveMessage={setSaveMessage}
              isEmptyNameField={isEmptyNameField}
            />
          </div>
          <div
            className="button footer-button"
            data-testid="edit-save-button"
            onClick={() => submitForm()}
          >
            Save
          </div>
        </div>
      </div>
      <div
        className="profile-security-wrapper"
        style={{ marginTop: '2rem' }}
        data-testid="profile-security-wrapper"
      >
        <div
          className="button danger"
          data-testid="profile-security-signout-button"
          onClick={signOutUser}
        >
          Sign Out
        </div>
      </div>
    </>
  )
}

export default ProfileSettingsForm
