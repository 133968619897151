import { getCurrentOrganization } from '../../shared/utilities'
import { NavLink } from 'react-router-dom'
import Avatar from '../Avatar'
import OrganizationSelectionMenu from '../OrganizationSelectionMenu'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import PeopleIcon from '@mui/icons-material/PeopleRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import useDocumentTitle from '../../shared/useDocumentTitle'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'
import { Changelog } from '../Changelog'
import { ProfileSelectionMenu } from '../ProfileSelectionMenu'
import { Feature } from '../../shared/features'
import { isFeatureEnabled } from '../../shared/posthog'

function NavBar () {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)

  const makeTitle = (name?: string) => {
    if (name) {
      return `${name} - ConnectBetter`
    }
    return 'ConnectBetter'
  }

  const isChangelogEnabled = isFeatureEnabled(Feature.Changelog)

  useDocumentTitle(makeTitle(currentOrganization?.name))

  return (
    <div className="nav-bar global">
      <div className="nav-items">
        <OrganizationSelectionMenu />
        <div className="nav-bar-mobile">
          <div className="nav-menu-items">
            <NavLink to="/">
              <TaskAltRoundedIcon /> Actions
            </NavLink>
            <NavLink to="/spaces" end data-testid="navbar-spaces-link">
              <GridViewRoundedIcon /> Spaces
            </NavLink>
            <NavLink to="/people"><PeopleIcon /> People </NavLink>
            <div className="nav-profile-container mobile">
              <NavLink to="/profile/settings">
                <Avatar
                  className="nav-avatar"
                  data-testid="navbar-avatar"
                  firstName={currentUser?.firstName}
                  lastName={currentUser?.lastName}
                  avatar={currentUser?.avatar}
                />
                <div className="nav-profile-name">You</div>
              </NavLink>
            </div>
          </div>

          {isChangelogEnabled && <Changelog />}

          <div className="nav-profile-container">
            <ProfileSelectionMenu />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar
