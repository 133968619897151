import { useParams, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { useState, useEffect } from 'react'
import { useConnectionQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { useEditor } from '../../shared/hooks'
import { isAuthorized } from '../../shared/permissions'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import { formatConnectionDate, isUrl, getCurrentOrganization } from '../../shared/utilities'
import { useModal } from '../../context/ModalContext'
import { useQueryClient } from '@tanstack/react-query'
import AttendanceAddon from './AttendanceAddon'
import GoalsAddon from './GoalsAddon'
import NotesAddon from './NotesAddon'
import AgreementAddon from './AgreementAddon'
import ActionsAddon from './ActionsAddon'
import Avatar from '../../components/Avatar'
import FreedomBuckTrackerAddon from './FreedomBuckTrackerAddon'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import useLayoutDetector from '../../shared/useLayoutDetector'
import CourseAddon from './CourseAddon'
import ConnectShareDropdown from '../../components/ConnectShareDropdown'
import LoadingScreen from '../../components/LoadingScreen'
import ReflectAddon from './ReflectAddon'
import CreateOrEditSpace from '../../modals/CreateOrEditSpace'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded'
import EditConnectionMenu from '../../components/EditConnectionMenu'
import ConnectionEnd from '../../modals/ConnectionEnd'
import { Tab, Tabs } from '@mui/material'
import { StatesArray, onAwarenessUpdateParameters } from '@hocuspocus/provider'
import { currentDateTime, parseDateTime } from '../../shared/dates'
import './index.css'

export type SharedOutsideAlerterType = {
  editConnectionRef?: React.MutableRefObject<any> | null
  editSpaceRef?: React.MutableRefObject<any> | null
}

function Connection () {
  useLayoutDetector()
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const { uid } = useParams()
  const queryClient = useQueryClient()
  const currentOrganization = getCurrentOrganization(currentUser)
  const navigate = useNavigate()

  const { isLoading, isError, error, data: connection } = useConnectionQuery(uid ?? '', { enabled: !!currentUser })

  const [connectedClients, setConnectedClients] = useState<StatesArray>([])
  const [navDisplay, setNavDisplay] = useState('')
  const [editAttendance, setEditAttendance] = useState(false)
  const [editCourse, setEditCourse] = useState(false)
  const [editFreedomChecks, setEditFreedomChecks] = useState(false)

  const isSpaceLeaderOrAbove = isAuthorized(
    getSpaceLeaderPolicy(connection?.spaceId, currentUser?.currentOrganizationId),
    currentUser
  )
  const readOnly = connection?.endTime != null
  const connectionAddonPreferences = connection?.space?.addonPreferences
    ? JSON.parse(connection?.space?.addonPreferences)
    : null
  const isConnectionUpcoming = parseDateTime(connection?.startTime) > currentDateTime()

  const editor = useEditor(`notes${uid}`, readOnly, {
    onAwarenessUpdate: async (data: onAwarenessUpdateParameters) => {
      setConnectedClients(data?.states)
      await queryClient.invalidateQueries(['exerciseList', parseInt(data?.states[0]?.user?.organizationRoleId)])
      await queryClient.invalidateQueries(['connections', data?.states[0]?.user?.uid])
    },
    showCollaborationCursor: true
  })

  function canEdit () : boolean {
    try {
      return isSpaceLeaderOrAbove &&
            connection?.endTime &&
            currentOrganization?.editConnection &&
            (JSON.parse(connection?.space?.addonPreferences ?? '')?.attendance || JSON.parse(connection?.space?.addonPreferences ?? '')?.course)
    } catch {
      return false
    }
  }

  function sortByOrder (a, b) {
    return order[a.id] - order[b.id]
  }

  function getAddonOrder (addons) {
    const order = {}
    addons.forEach((addon) => {
      order[addon.id] = addon.order
    })
    return order
  }

  function getEnabledAddons (addons) {
    const order = {}
    addons.forEach((addon) => {
      order[addon.id] = addon.enabled
    })
    return order
  }

  const addons = [
    {
      component: CourseAddon,
      id: 'course',
      props: {
        connection,
        currentUser,
        editCourse
      },
      order: 1,
      enabled: !!connectionAddonPreferences?.course && connection?.space?.courseVersionId
    },
    {
      component: NotesAddon,
      id: 'notes',
      props: {
        editor,
        connection,
        readOnly
      },
      order: 2,
      enabled: !!connectionAddonPreferences?.notes
    },
    {
      component: AgreementAddon,
      id: 'agreement',
      props: {
        connection,
        uid,
        readOnly
      },
      order: 3,
      enabled: !!connectionAddonPreferences?.agreement
    },
    {
      component: ActionsAddon,
      id: 'actions',
      props: {
        connection
      },
      order: 4,
      enabled: !!connectionAddonPreferences?.actions
    },
    {
      component: AttendanceAddon,
      id: 'attendance',
      props: {
        connection,
        editAttendance
      },
      order: 5,
      enabled: !!connectionAddonPreferences?.attendance && !isConnectionUpcoming && isSpaceLeaderOrAbove
    },
    {
      component: FreedomBuckTrackerAddon,
      id: 'freedomChecks',
      props: {
        connection,
        editFreedomChecks
      },
      order: 6,
      enabled: isFreedomChecksEnabled()
    },
    {
      component: GoalsAddon,
      id: 'goals',
      props: {
        currentUser
      },
      order: 7,
      enabled: !!connectionAddonPreferences?.goals
    },
    {
      component: ReflectAddon,
      id: 'reflect',
      props: {
        connection,
        uid,
        readOnly
      },
      order: 8,
      enabled: false
    }
  ]

  function isFreedomChecksEnabled () {
    return !isConnectionUpcoming &&
      isSpaceLeaderOrAbove &&
      !!connectionAddonPreferences?.attendance &&
      connection?.space?.spacePlugins?.length !== 0 &&
      connection?.space?.courseVersion?.courseId === connection?.space?.spacePlugins?.find((plugin) => plugin.enabled)?.plugin?.courseId
  }

  const addonsEnabled = getEnabledAddons(addons)

  const [order, setOrder] = useState(getAddonOrder(addons))
  const [pinned, setPinned] = useState('course')
  const [editConnect, setEditConnect] = useState(false)
  const [mobileSelectedTab, setMobileSelectedTab] = useState(0)

  function useSharedOutsideAlerter ({ editConnectionRef, editSpaceRef }: SharedOutsideAlerterType) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (!!editSpaceRef && editSpaceRef?.current && !editSpaceRef?.current?.contains(event.target)) {
          setNavDisplay('')
        }
        if (!!editConnectionRef && editConnectionRef?.current && !editConnectionRef?.current?.contains(event.target)) {
          setEditConnect(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [editConnectionRef, editSpaceRef])
  }

  useEffect(() => {
    const getDefaultPinned = () => {
      if (connectionAddonPreferences) {
        setPinned(addons.filter((addon) => addon.enabled).sort(sortByOrder)[0]?.id)
      }
    }
    getDefaultPinned()
  }, [connectionAddonPreferences, isLoading])

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
    return <LoadingScreen />
  }

  const leaveClickHandler = () => {
    if (parseDateTime(connection?.startTime) < currentDateTime() && !connection?.endTime) {
      makeModal({
        modal: <ConnectionEnd connection={connection} isSpaceLeaderOrAbove={isSpaceLeaderOrAbove} />,
        title: 'Are you sure you want to leave?'
      })
    } else {
      navigate(-1)
    }
  }

  function swapPinned (id: string) {
    setOrder({ ...order, [id]: order[pinned], [pinned]: order[id] })
    setPinned(id)
  }

  const addonsToRender = addons.sort(sortByOrder).filter((addon) => addonsEnabled[addon.id])

  const getAvatarForConnectedClient = (connectedClient: any) => {
    return (
      connection?.space?.spaceRoles?.find(
        (spaceRole) => spaceRole.organizationRoleId === connectedClient.user?.organizationRoleId
      )?.organizationRole?.user?.avatar ?? ''
    )
  }

  return (
    <div className="connection">
      <header>
        <div className="left">
          <div className="connection-left-sub-header-container">
            <div className="space-name">{connection?.space?.name}</div>
            <div className="space-purpose-dropdown">{connection?.space?.purpose}</div>
            <div className="connection-date">{formatConnectionDate(connection?.startTime)}</div>
            {connection?.endTime && <div className="connection-status">View Only</div>}
            {parseDateTime(connection?.startTime) > currentDateTime() && !connection?.endTime && (
              <div className="connection-status">Prepare</div>
            )}
            {canEdit() &&
            (
              <EditConnectionMenu
                useSharedOutsideAlerter={useSharedOutsideAlerter}
                editConnect={editConnect}
                setEditConnect={setEditConnect}
                hasAttendance={
                  !!connectionAddonPreferences?.attendance && !isConnectionUpcoming && isSpaceLeaderOrAbove
                }
                hasCourse={!!connectionAddonPreferences?.course && connection?.space?.courseVersionId}
                swapPinned={swapPinned}
                setEditCourse={setEditCourse}
                setEditAttendance={setEditAttendance}
                setEditFreedomChecks={setEditFreedomChecks}
                hasFreedomChecksEnabled={isFreedomChecksEnabled()}
              />
            )}
          </div>
        </div>
        <div className="right">
          <div className="users-in-connection">
            {connectedClients?.map((connectedClient) => {
              if (connectedClient.user?.name && connectedClient.user?.color) {
                return (
                  <Avatar
                    key={connectedClient.clientId}
                    className="nav-avatar"
                    fullName={connectedClient.user?.name}
                    style={{ backgroundColor: connectedClient.user?.color }}
                    avatar={getAvatarForConnectedClient(connectedClient)}
                  />
                )
              }
              return null
            })}
          </div>
          <div className="actions">
            {connection?.space?.location && isUrl(connection.space.location) && (
              <a
                className="button subnav-button transparent call"
                target="_blank"
                href={connection.space.location}
                rel="noreferrer"
              >
                Join Call <ArrowOutwardRoundedIcon />
              </a>
            )}
            <div
              className="button subnav-button transparent share-connect"
              onClick={() => {
                navDisplay === '' || navDisplay === 'settings' ? setNavDisplay('share') : setNavDisplay('')
              }}
            >
              Share
            </div>
            {navDisplay === 'share' && (
              <ConnectShareDropdown useSharedOutsideAlerter={useSharedOutsideAlerter} setNavDisplay={setNavDisplay} />
            )}
            <div className="button subnav-button danger" onClick={leaveClickHandler}>
              Leave
            </div>
            {isSpaceLeaderOrAbove && (
              <div
                className="button subnav-button transparent icon"
                onClick={() => {
                  makeModal({ modal: <CreateOrEditSpace existingSpace={connection?.space} />, title: 'Edit Space' })
                }}
              >
                <MoreHorizIcon />
              </div>
            )}
          </div>
        </div>
      </header>
      <div className="mobile-addons-tabs">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={mobileSelectedTab}
          onChange={(_, value) => setMobileSelectedTab(value)}
        >
          {addonsToRender.map((addon) => {
            if (addon.id === 'freedomChecks') {
              return <Tab key={addon.id} label="Freedom Checks" />
            }
            return <Tab key={addon.id} label={addon.id} />
          })}

        </Tabs>
      </div>
      <div className="addons">
        {addonsToRender.map((addon, index) => (
          <div key={addon.id} className={`${mobileSelectedTab === index ? 'active-on-mobile' : ''}`}>
            <addon.component
              pinned={pinned === addon.id}
              id={addon.id}
              className={addon.id}
              setAsPinned={() => swapPinned(addon.id)}
              {...addon.props}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Connection
