import posthog from 'posthog-js'
import { config } from './config'
import { Feature } from './features'

function setupPostHog () {
  if (config.POSTHOG.API_KEY) {
    posthog.init(config.POSTHOG.API_KEY, {
      api_host: 'https://app.posthog.com'
    })
  }
}

function isFeatureEnabled (feature: Feature) {
  if (!config.POSTHOG.API_KEY) {
    return true
  }
  return posthog.isFeatureEnabled(feature)
}

export { setupPostHog, isFeatureEnabled }
