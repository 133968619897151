import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink
} from '@react-pdf/renderer'
import Button from '../Button'
import { Download } from '@mui/icons-material'
import { getFileDate } from './utils'
import { Connection } from '../../types'
import { formatConnectionDate } from '../../shared/utilities'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 30
  },
  metricsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    gap: 10
  },
  metricContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    flex: 1,
    borderColor: '#ccc'
  },
  metric: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5
  },
  metricLabel: {
    fontSize: 12,
    color: 'gray'
  },
  reportTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5
  },
  reportTimePeriod: {
    fontSize: 14,
    color: 'gray',
    marginBottom: 20
  },
  space: {
    marginBottom: 15
  },
  spaceName: {
    fontSize: 16,
    marginBottom: 5
  },
  normal: {
    fontSize: 12,
    marginBottom: 5
  },
  spaceConnectedOn: {
    fontSize: 11,
    color: 'gray',
    marginBottom: 5
  },
  attendee: {
    marginLeft: 10,
    fontSize: 12,
    marginBottom: 5
  },
  spaceMetaContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 10
  }
})

type SpaceAttendance = {
  fullName: string;
};

type SpaceReport = {
  name: string;
  attendees: SpaceAttendance[];
  connectedOn: string;
};

const makeSpaceReport = (connections: Connection[]): SpaceReport[] => {
  return connections.map((connection) => {
    return {
      name: connection.space?.name ?? '',
      connectedOn: formatConnectionDate(connection.endTime) ?? '',
      attendees:
        connection.connectionAttendance?.map((attendance) => {
          return {
            fullName: `${attendance.contact?.firstName} ${attendance.contact?.lastName}`
          }
        }) ?? []
    }
  })
}

interface ReportProps {
  connections: Connection[];
  timePeriod?: string;
  totalWeeklyAttendance?: number;
  totalSpacesWithConnects?: number;
}

const ReportAsPdf = ({
  connections,
  timePeriod,
  totalWeeklyAttendance,
  totalSpacesWithConnects
}: ReportProps) => {
  const spaces = makeSpaceReport(connections)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.reportTitle}>Weekly Report</Text>
        <Text style={styles.reportTimePeriod}>{timePeriod}</Text>

        <View style={styles.metricsContainer}>
          <View style={styles.metricContainer}>
            <Text style={styles.metric}>{totalWeeklyAttendance ?? 0}</Text>
            <Text style={styles.metricLabel}>Total Attendance</Text>
          </View>
          <View style={styles.metricContainer}>
            <Text style={styles.metric}>{totalSpacesWithConnects ?? 0}</Text>
            <Text style={styles.metricLabel}>Spaces with Connects</Text>
          </View>
        </View>

        {spaces.map((space, index) => space.attendees.length > 0
          ? (
          <View key={index} style={styles.space}>
            <View style={styles.spaceMetaContainer}>
              <Text style={styles.spaceName}>{space.name} - {space.attendees.length}</Text>
              <Text style={styles.spaceConnectedOn}>{space.connectedOn}</Text>
            </View>
            {space.attendees.map((item, idx) => (
              <Text key={idx} style={styles.attendee}>
                {item.fullName}
              </Text>
            ))}
          </View>
            )
          : null)}
      </Page>
    </Document>
  )
}

export const DownloadWeeklyReportPdf = (props: ReportProps) => {
  return (
    <PDFDownloadLink
      document={ <ReportAsPdf {...props} /> }
      fileName={`${getFileDate()}_weekly_attendance_report.pdf`}
    >
      {({ loading }) =>
        loading
          ? null
          : (
          <Button secondary size="sm">
            <Download fontSize="small" /> Download PDF
          </Button>
            )
      }
    </PDFDownloadLink>
  )
}
