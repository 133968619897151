import { useUpdateOrganizationCourseMutation, useSpacesQuery } from '../../shared/queryHooks'
import { getPeople } from '../../shared/api'
import { useQueryClient } from '@tanstack/react-query'
import { useModal } from '../../context/ModalContext'
import { PuffLoader } from 'react-spinners'
import { useState, useEffect } from 'react'
import { Checkbox } from '@mui/material'
import Avatar from '../../components/Avatar'
import { useAuthContext } from '../../context/AuthContext'
import { OrganizationCourse } from '../../types'
import './index.css'

interface EditOrganizationRolesOrganizationCourseProps {
  organizationCourse: OrganizationCourse
}

type FormattedRole = {
  organizationRoleId: number,
  access: boolean,
  firstName?: string,
  lastName?: string,
  email?: string,
  avatar?: string | null
  isLocked: boolean
}

function EditOrganizationRolesOrganizationCourse ({ organizationCourse }: EditOrganizationRolesOrganizationCourseProps) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuthContext()
  const { clearModal } = useModal()
  const updateOrganizationRoleAccess = useUpdateOrganizationCourseMutation()
  const { data: spaces } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser })

  const [organizationRoles, setOrganizationRoles] = useState<FormattedRole[]>([])
  const [filteredOrganizationRoles, setFilteredOrganizationRoles] = useState<FormattedRole[]>([])
  const [organizationRoleSearch, setOrganizationRoleSearch] = useState('')
  const [allSelected, setAllSelected] = useState(false)

  useEffect(() => {
    const fetchOrganizationRoles = async () => {
      const organizationRoles = await getOrganizationRoles()
      const organizationRoleAccess = JSON.parse(organizationCourse?.organizationRoleAccess ?? '{}')
      const formattedRoles = organizationRoles?.map(role => {
        const access = (organizationRoleAccess?.find(organizationRole => organizationRole?.organizationRoleId === role?.id))?.access
        const locked = spaces?.some(space => space.courseVersionId === organizationCourse?.courseVersionId && role.spaceRoles?.some(spaceRole => spaceRole.spaceId === space.id)) ?? false
        return (
          { organizationRoleId: role?.id, access, firstName: role?.contact?.firstName, lastName: role?.contact?.lastName, email: role?.user?.email, avatar: role?.user?.avatar, isLocked: locked }
        )
      })
      setOrganizationRoles(formattedRoles)
    }
    void fetchOrganizationRoles()
  }, [])

  useEffect(() => {
    updateDisplay(organizationRoles)
  }, [organizationRoleSearch])

  useEffect(() => {
    updateDisplay(organizationRoles)
  }, [organizationRoles])

  function updateDisplay (formattedRoles: FormattedRole[]) {
    let filteredRoles: FormattedRole[] = []
    if (organizationRoleSearch) {
      filteredRoles = formattedRoles.filter(role => `${role?.firstName} ${role?.lastName}`.toLowerCase().includes(organizationRoleSearch.toLowerCase()))
    } else {
      filteredRoles = formattedRoles
    }
    setFilteredOrganizationRoles(filteredRoles)
  }

  function handleChange ({ organizationRoleId, access }) {
    setOrganizationRoles(prevState => (prevState.map(state => state.organizationRoleId === organizationRoleId ? { ...state, access } : state)))
  }

  function handleSelectAll (selectAll: boolean) {
    setOrganizationRoles(prevState => (prevState.map(state => filteredOrganizationRoles.some(fr => fr.organizationRoleId === state.organizationRoleId && !fr.isLocked) ? { ...state, access: !allSelected } : state)))
    setAllSelected(selectAll)
  }

  async function handleSubmit () {
    await updateOrganizationRoleAccess.mutateAsync({ id: organizationCourse?.id ?? 0, access: organizationRoles, enabled: true, requiresReview: organizationCourse?.courseVersion?.requiresReview, actionsApproved: organizationCourse?.courseVersion?.actionsApproved })
    clearModal()
  }

  async function getOrganizationRoles () {
    return await queryClient.fetchQuery([`organization:${currentUser?.currentOrganizationId})}`, 'people'], () => getPeople())
  }

  const organizationRoleDisplay = (role: FormattedRole) => {
    return (
      <div key={role?.organizationRoleId} className="organization-role-daily-checkin-item">
          <div className="daily-checkin-user-item">
            <Avatar className="nav-avatar" firstName={role?.firstName} lastName={role?.lastName} avatar={role?.avatar} />
            <div>
              <div className="name">{role?.firstName} {role?.lastName}{role.isLocked ? '*' : ''}</div>
              {role?.email && <div className="email">{role?.email}</div>}
            </div>
          </div>
          <Checkbox name={'CB_' + role?.organizationRoleId} disabled={role?.isLocked} checked={role?.access} onChange={() => handleChange({ organizationRoleId: role?.organizationRoleId, access: !role?.access })}></Checkbox>
      </div>
    )
  }

  return (
    <>
      <div className='modal-header search-bar-container' style={{ padding: 0 }}>
        <input
          name="search"
          type='text'
          placeholder='Search...'
          className="search-bar"
          value={organizationRoleSearch}
          onChange={e => (setOrganizationRoleSearch(e.target.value))}
        />
        <div className='select-all-checkbox'>
          <Checkbox name='select_all' checked={allSelected} onChange={() => handleSelectAll(!allSelected)} />Select All
        </div>
      </div>
      <div className='content-display'>
        <>
          <div className="manage-user-spaces-results">
            {filteredOrganizationRoles.length > 0
              ? filteredOrganizationRoles.sort((roleA, roleB) => {
                return roleA.firstName?.localeCompare(roleB.firstName ?? '') ?? 0
              }).map(role => organizationRoleDisplay(role))
              : <div key={0}>No items found</div>}
          </div>
        </>
      </div>
      <div className='locked-message'>
        * This person is locked into this course because they are a member of a space that is enrolled in this program.
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button" onClick={() => handleSubmit()}>{updateOrganizationRoleAccess?.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Save'}</div>
      </div>
    </>
  )
}

export default EditOrganizationRolesOrganizationCourse
